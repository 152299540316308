import React from "react";
import Textfield from "../components/inputs/Textfield";
import Button from "../components/inputs/Button";
import reqwest from "reqwest";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { Link } from "react-router";
import queryString from "query-string";
import { loginAPI } from "../settings";

class Login extends React.Component {
  constructor(props) {
    super(props);
    let error = false;
    if (window.location.href.indexOf("session=false") > -1) {
      error = "Your session has expired.<br /> Please log in again.";
    }
    const urlParams = queryString.parse(window.location.search.toLowerCase());
    const cartURL = window.POST["hook_url"] || urlParams.hook_url;
    if (cartURL) {
      window.location.href = `/${window.location.search}`;
    }
    this.state = {
      error,
    };
  }

  componentDidMount() {
    document.addEventListener("keyup", this._submitOnEnter);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this._submitOnEnter);
  }

  _submitOnEnter = (event) => {
    event.preventDefault();
    if (event.keyCode == 13) {
      this._login();
    }
  };

  _login = () => {
    const userlogin = this._username._input.value;
    const password = this._password._input.value;
    reqwest({
      url: loginAPI,
      data: {
        dataset: "user",
        userlogin,
        password,
      },
      method: "get",
      crossOrigin: true,
      success: (data) => {
        data = JSON.parse(data);
        const sessionID = data[0].sessionid;
        if (sessionID) {
          Cookies.set("username", userlogin);
          Cookies.set("sessionID", sessionID);
          Cookies.set("password", Base64.encode(password));
          const prevUrl = localStorage.getItem("prevURL");
          if (prevUrl) {
            localStorage.removeItem("prevURL");
            window.location.href = prevUrl;
          } else {
            window.location.href = "/";
          }
        } else {
          this.setState({
            error:
              "Either your login failed or your session expired. <br /> Please try again or contact us for assistance.",
          });
        }
      },
    });
  };

  render() {
    return (
      <div className="main-content">
        <div className="Login">
          <h2 className="PageTitle">Login</h2>
          {this.state.error && (
            <div
              style={{ textAlign: "center", color: "red" }}
              dangerouslySetInnerHTML={{ __html: this.state.error }}
            />
          )}
          <Textfield
            ref={(node) => (this._username = node)}
            placeholder="Username"
          />
          <Textfield
            type="password"
            ref={(node) => (this._password = node)}
            placeholder="Password"
          />
          <Button onClick={this._login}>Login</Button>
          <Link
            to="/requestpasswordreset"
            style={{ position: "absolute", right: "15px", bottom: "15px" }}
          >
            Reset Password
          </Link>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  children: React.PropTypes.node,
};

Login.defaultProps = {
  children: null,
};

export default Login;

export default {
  CART_LOADING: "CART_LOADING",
  CART_UPDATE_FROM_SERVER: "CART_UPDATE_FROM_SERVER",
  CART_ADD: "CART_ADD",
  CART_UPDATE: "CART_UPDATE",
  CART_REMOVE: "CART_REMOVE",
  CART_UPDATE_CLIENT_INFO: "CART_UPDATE_CLIENT_INFO",
  CART_UPDATE_AVAILABLE_CARTS: "CART_UPDATE_AVAILABLE_CARTS",
  CART_UPDATE_SELECTED_CART: "CART_UPDATE_SELECTED_CART",
  ADD_BOOK: "ADD_BOOK",
  REMOVE_BOOK: "REMOVE_BOOK",
  ADD_CRAWLED_BOOK: "ADD_CRAWLED_BOOK",
  REMOVE_CRAWLED_BOOK: "REMOVE_CRAWLED_BOOK",
  SHOW_CRAWLED_BOOKS: "SHOW_CRAWLED_BOOKS",
  CLEAR_BOOKS: "CLEAR_BOOKS"
};

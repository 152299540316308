import React from "react";
// import Row from './Row';
import Column from "./Column";

const Table = props => {
  return (
    <div className="Table">
      <div className="Table-heading">
        {props.columns.map((item, index) => {
          return <Column key={index}>{item}</Column>;
        })}
      </div>
      {props.children}
    </div>
  );
};

Table.propTypes = {
  children: React.PropTypes.node,
  columns: React.PropTypes.array
};

Table.defaultProps = {
  children: null
};

export default Table;

import React from "react";

const TableRow = props => {
  return (
    <div className={`Table-row ${props.className}`}>
      {props.children}
    </div>
  );
};

TableRow.propTypes = {
  children: React.PropTypes.node,
  className: React.PropTypes.string
};

TableRow.defaultProps = {
  children: null,
  className: ""
};

export default TableRow;

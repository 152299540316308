import React from "react";
import Textfield from "../components/inputs/Textfield";
import Button from "../components/inputs/Button";
import reqwest from "reqwest";
import { loginAPI } from "../settings";

class RequestPasswordreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  _sendToken = () => {
    const userlogin = this._username._input.value;
    reqwest({
      url: loginAPI,
      data: {
        dataset: "password",
        userlogin: userlogin,
        action: "gettoken",
      },
      method: "get",
      crossOrigin: true,
      success: (data) => {
        data = JSON.parse(data);
        const success = data[0].success;
        if (success == "1") {
          this.setState({
            success: "OK! We sent you an e-mail with your password reset link.",
            error: null,
          });
        } else {
          this.setState({
            error: "Oops...something went wrong. Please try again",
            success: null,
          });
        }
      },
    });
  };

  render() {
    return (
      <div className="main-content">
        <div className="Login">
          <h2 className="PageTitle">Reset Password</h2>
          {this.state.error && (
            <div
              style={{ textAlign: "center", color: "red" }}
              dangerouslySetInnerHTML={{ __html: this.state.error }}
            />
          )}
          {this.state.success && (
            <div
              style={{ textAlign: "center", color: "#00A244" }}
              dangerouslySetInnerHTML={{ __html: this.state.success }}
            />
          )}
          <Textfield
            ref={(node) => (this._username = node)}
            placeholder="Username"
          />
          <Button onClick={this._sendToken}>Reset Password</Button>
        </div>
      </div>
    );
  }
}

export default RequestPasswordreset;

import React from "react";
import currencyFormatter from "currency-formatter";
import { AllHtmlEntities } from "html-entities";
import Modal from "../../components/Modal";
import Button from "../../components/inputs/Button";
import Checkbox from "../../components/inputs/Checkbox";
import Textfield from "../../components/inputs/Textfield";
import Selectbox from "../inputs/Selectbox";
import moment from "moment";
import { connect } from "react-redux";
import { updateCart } from "../../actions/actions";
import Cookies from "js-cookie";
import reqwest from "reqwest";
import Fetch from "../Fetch";
import queryString from "query-string";
import { bakScarterAPI } from "../../settings";

const entities = new AllHtmlEntities();

class ShoppingCartItem extends React.Component {
  constructor(props) {
    super(props);

    let showSpine = false;
    let showShelfReady = false;
    if (props.clientInfo.showspine == "1" || props.item.spine == "1") {
      showSpine = true;
    }
    if (props.clientInfo.showshelfready == "1") {
      showShelfReady = true;
    }

    this.state = {
      modalOpen: false,

      requestRTA: false,
      rtaLibraries: [],
      rtaFunds: [],
      rtaReportingCodes: [],
      rtaReportingCodes2: [],
      rtaInterestedUser: {},
      rtaReceivingNote: {},

      fundDescription: props.item.fundDescription,
      libraryDescription: props.item.libraryDescription,
      locationDescription: props.item.locationDescription,

      spineLabel: showSpine,
      shelfready: showShelfReady,

      placeorder: props.placeorder || "1",
      fund: props.fund || "",
      library: props.library || "",
      location: props.location || "",
      reportingCode: props.reportingCode || "",
      reportingCode2: props.reportingCode2 || "",
      interestedUser: props.interestedUser || "",
      receivingNote: props.receivingNote || "",
    };
  }

  componentDidMount() {
    document.addEventListener("scart:select-all", () => {
      this.setState(
        {
          placeorder: "1",
        },
        () => {
          this._submitUpdate();
        }
      );
    });

    document.addEventListener("scart:unselect-all", () => {
      this.setState(
        {
          placeorder: "0",
        },
        () => {
          this._submitUpdate();
        }
      );
    });
  }

  _fetchRtaData() {
    if (this.props.rtaApiKey) {
      return new Promise((resolve) => {
        const librariesRequest = reqwest({
          url: bakScarterAPI,
          data: {
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "libraries",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        const reportingCodesRequest = reqwest({
          url: bakScarterAPI,
          data: {
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "reportingCodes",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        const reportingCodes2Request = reqwest({
          url: bakScarterAPI,
          data: {
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "reportingCodes2",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        const interestedUserRequest = reqwest({
          url: bakScarterAPI,
          data: {
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "interestedUser",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        const receivingNoteRequest = reqwest({
          url: bakScarterAPI,
          data: {
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "receivingNote",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        const fundRequest = reqwest({
          url: bakScarterAPI,
          data: {
            limit: 100,
            view: "full",
            apikey: this.props.rtaApiKey,
            rtaAPI: 1,
            action: "funds",
          },
          type: "json",
          method: "GET",
          crossOrigin: true,
        });
        Promise.all([
          librariesRequest,
          fundRequest,
          reportingCodesRequest,
          reportingCodes2Request,
          interestedUserRequest,
          receivingNoteRequest,
        ]).then(
          ([
            libraries,
            funds,
            reportingCodes,
            reportingCodes2,
            interestedUser,
            receivingNote,
          ]) => {
            this.setState(
              {
                rtaLibraries: libraries.library || [],
                rtaFunds: funds.fund || [],
                rtaReportingCodes: reportingCodes || [],
                rtaReportingCodes2: reportingCodes2 || [],
                rtaInterestedUser: interestedUser || {},
                rtaReceivingNote: receivingNote || {},
              },
              () => {
                resolve();
              }
            );
          }
        );
      });
    }
  }

  _onRemove = () => {
    this.props.onRemove(this.props.index);
  };

  _submitUpdate = async () => {
    this.setState({
      modalOpen: false,
    });

    let rtaOrderData = {};

    const kref = this._kref?._input.value || this.props.item.kref || "";
    const copy = this._copy?._input.value || this.props.item.copy || "1";
    const urgt = this._urgt?._input.checked ? "1" : "0";
    const details =
      this._details?._input.value || this.props.item.details || "";
    let code = "";

    if (!this.state.requestRTA) {
      code = this._code?._input.value || this.props.item.code || "";
    }

    let fundDescription = "";
    let libraryDescription = "";
    let locationDescription = "";

    if (this.state.requestRTA) {
      fundDescription = this.state.rtaFunds.find(
        (fc) => fc.code == this.state.fund
      ).name;
      libraryDescription = this.state.rtaLibraries.find(
        (l) => l.code == this.state.library
      ).name;
      locationDescription = this.state.locationDescription;

      rtaOrderData = {
        fund: this.state.fund,
        fundDescription,
        library: this.state.library,
        libraryDescription,
        location: this.state.location,
        locationDescription,
        reportingCode: this.state.reportingCode || "",
        reportingCode2: this.state.reportingCode2 || "",
        interestedUser: this.state.interestedUser || "",
        receivingNote: this.state.receivingNote || "",
      };
    }

    let spineData = {};
    if (this.state.spineLabel) {
      spineData = {
        spine: 1,
        spinecolor: this.state.color || "",
        spineline1: this._line1._input.value || "",
        spineline2: this._line2._input.value || "",
        spineline3: this._line3._input.value || "",
        spineline4: this._line4._input.value || "",
        spineline5: this._line5._input.value || "",
        spineline6: this._line6._input.value || "",
      };
    } else {
      spineData = {
        spine: 0,
        spinecolor: "",
        spineline1: "",
        spineline2: "",
        spineline3: "",
        spineline4: "",
        spineline5: "",
        spineline6: "",
      };
    }

    let shelfReadyData = {};
    console.log(this.state.shelfready);
    if (this.state.shelfready) {
      shelfReadyData = {
        shelfready: 1,
        shelfreadydetails: this._shelfReadyDetails._input.value || "",
      };
    } else {
      shelfReadyData = {
        shelfready: 0,
        shelfreadydetails: "",
      };
    }

    const notRtaOrderData = {
      code: code.toUpperCase(),
    };

    if (this.state.requestRTA) {
      const libaries = await reqwest({
        type: "json",
        method: "GET",
        crossOrigin: true,
        url: bakScarterAPI,
        data: {
          rtaAPI: 1,
          action: "locations",
          library: rtaOrderData.library,
          apikey: this.props.rtaApiKey,
        },
      });

      if (!libaries.total_record_count) {
        rtaOrderData.location = rtaOrderData.library;
      }
    }

    this.props.updateCart(Cookies.get("subaccount"), this.props.index, {
      isbn: this.props.item.isbn || "",
      titl: this.props.item.titl || "",
      auth: this.props.item.auth || "",
      kdat: moment().format("DD.MM.YYYY"),
      pdat: this.props.item.pdat || "",
      weig: this.props.item.weig || "",
      publ: this.props.item.publ || "",
      placeorder: this.state.placeorder,
      kref,
      copy,
      urgt,
      detl: details,
      ...(!this.state.requestRTA && notRtaOrderData),
      ...(this.state.requestRTA && rtaOrderData),
      ...(this.state.spineLabel && spineData),
      ...(this.state.shelfready && shelfReadyData),
    });

    this.setState({
      fundDescription,
      libraryDescription,
      locationDescription,
    });
  };

  _openEdit = async () => {
    if (this.props.rtaApiKey) {
      await this._fetchRtaData();
      this.setState({
        requestRTA: true,
        modalOpen: true,
      });
    } else {
      this.setState({
        modalOpen: true,
      });
    }
  };

  _onUpdateQuantity = () => {
    this.props.onUpdateQuantity(this.props.index, this._inputQuantity.value);
  };

  render() {
    const urlParams = queryString.parse(window.location.search.toLowerCase());
    let isPunchout = window.POST["hook_url"] || urlParams.hook_url;

    let showSpine = false;
    let showShelfReady = false;
    let showShelfReadyDetails = false;
    if (
      this.props.clientInfo.showspine == "1" ||
      this.props.item.spine == "1"
    ) {
      showSpine = true;
    }

    if (this.props.clientInfo.showshelfready == "1") {
      showShelfReady = true;
    }
    if (this.props.clientInfo.showshelfreadydetails == "1") {
      showShelfReadyDetails = true;
    }

    return (
      <div className="shopping-cart-item">
        {!isPunchout && (
          <Checkbox
            name={`placeorder__${this.props.isbn}__${this.props.index}`}
            label=""
            onclick={(value) => {
              this.setState(
                {
                  placeorder: value ? "1" : "0",
                },
                () => {
                  this._submitUpdate();
                }
              );
            }}
            checked={this.state.placeorder === "1" ? true : false}
          />
        )}
        <div className="shopping-cart-item-data">
          <div className="item-isbn">{this.props.isbn}</div>
          <div className="item-title">{this.props.title}</div>
          {this.props.konto && (
            <div>
              <b>Sub-Account:</b> {this.props.konto}
            </div>
          )}
          {this.props.budgetCode && (
            <div>
              <b>Budget Code:</b> {this.props.budgetCode}
            </div>
          )}
          {this.props.reference && (
            <div>
              <b>Reference:</b> {this.props.reference}
            </div>
          )}
          {this.props.ebrary && (
            <div>
              <b>Licence:</b> {this.props.ebrary}
            </div>
          )}
          {this.props.rtaApiKey && this.state.fundDescription && (
            <div>
              <b>Fund Code:</b> {this.state.fundDescription}
            </div>
          )}
          {this.props.rtaApiKey && this.state.libraryDescription && (
            <div>
              <b>Library:</b> {this.state.libraryDescription}
            </div>
          )}
          {this.props.rtaApiKey && this.state.locationDescription && (
            <div>
              <b>Location:</b> {this.state.locationDescription}
            </div>
          )}
          {this.props.author && (
            <div className="item-author">
              {typeof this.props.author == "object" &&
              this.props.author.length > 0 ? (
                <span>by </span>
              ) : null}
              {typeof this.props.author == "object" &&
                this.props.author &&
                this.props.author.map((item, index) => {
                  if (!item) {
                    return;
                  } else if (index == 0) {
                    return <span key={index}>{entities.decode(item)}</span>;
                  } else {
                    return <span key={index}>, {entities.decode(item)}</span>;
                  }
                })}
            </div>
          )}
          <div className="item-footer">
            <div className="item-inputs">{this.props.offerTextDescription}</div>
            <div className="item-price">
              {currencyFormatter.format(
                this.props.price * this.props.quantity,
                {
                  symbol: this.props.currency,
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                }
              )}
            </div>
          </div>
          <br />
          <div className="item-inputs">
            {this.props.item.editable ? (
              <input
                ref={(node) => (this._inputQuantity = node)}
                type="number"
                min="1"
                className="item-quantity"
                defaultValue={this.props.quantity}
                key={this.props.quantity}
                onChange={this._onUpdateQuantity}
              />
            ) : null}
            {this.props.item.editable ? (
              <button
                type="button"
                className="item-refresh"
                onClick={this._onUpdateQuantity}
              >
                <img src="/images/icons/refresh.png" alt="" />
              </button>
            ) : null}
            {this.props.item.editable ? (
              <button
                type="button"
                className="item-edit"
                onClick={() => this._openEdit()}
              >
                <Modal open={this.state.modalOpen} class="Modal-wide">
                  <Textfield
                    alignLabel="left"
                    label="Reference"
                    ref={(node) => (this._kref = node)}
                    placeholder="Your Reference"
                    defaultValue={this.props.item.kref}
                  />
                  {!this.state.requestRTA && (
                    <Textfield
                      alignLabel="left"
                      label="Budget Code"
                      ref={(node) => (this._code = node)}
                      placeholder="Your Budget Code"
                      autocompleteBudgetCode={Cookies.get("subaccount")}
                      defaultValue={this.props.item.code}
                    />
                  )}
                  <Textfield
                    alignLabel="left"
                    label="Copies"
                    type="number"
                    ref={(node) => (this._copy = node)}
                    placeholder="Copies"
                    defaultValue={this.props.item.copy}
                  />
                  <Textfield
                    alignLabel="left"
                    label="Order Date"
                    ref={(node) => (this._kdat = node)}
                    disabled={true}
                    value={moment().format("DD.MM.YYYY")}
                    placeholder="Order Date"
                  />
                  <Checkbox
                    ref={(node) => (this._urgt = node)}
                    onClick={(value) => value}
                    value="1"
                    label="Urgent"
                    name={`urgent_${this.props.isbn}_${this.props.index}`}
                  />
                  <Textfield
                    type="textarea"
                    ref={(node) => (this._details = node)}
                    placeholder="Details - note to Delbanco"
                    defaultValue={this.props.item.detl}
                  />
                  {this.state.requestRTA && (
                    <div>
                      <hr />
                      <strong>RTA Specific Information</strong>
                      <Selectbox
                        defaultValue={this.state.fund}
                        alignLabel="left"
                        label="Fund Code *"
                        required={true}
                        updateAction={(value) => {
                          this.setState({
                            fund: value,
                          });
                        }}
                        options={this.state.rtaFunds.map((item) => {
                          return {
                            name: `${item.name} (${item.code})`,
                            value: `${item.code}`,
                          };
                        })}
                      />
                      <Selectbox
                        defaultValue={this.state.library}
                        alignLabel="left"
                        label="Library *"
                        required={true}
                        updateAction={(value) => {
                          this.setState({
                            library: value,
                          });
                        }}
                        options={this.state.rtaLibraries.map((item) => {
                          return {
                            name: `${item.name}`,
                            value: `${item.code}`,
                          };
                        })}
                      />
                      {this.state.library && (
                        <Fetch
                          url={bakScarterAPI}
                          addSessionData={false}
                          data={{
                            rtaAPI: 1,
                            action: "locations",
                            library: this.state.library,
                            apikey: this.props.rtaApiKey,
                          }}
                        >
                          {({ data }) => {
                            if (data && data.total_record_count > 0) {
                              return (
                                <Selectbox
                                  defaultValue={this.state.location}
                                  alignLabel="left"
                                  label="Location *"
                                  required={true}
                                  updateAction={(value) => {
                                    const locationDescription =
                                      data.location.find(
                                        (l) => l.code === value
                                      ).name;
                                    this.setState({
                                      location: value,
                                      locationDescription,
                                    });
                                  }}
                                  options={data.location.map((item) => {
                                    return {
                                      name: `${item.name}`,
                                      value: `${item.code}`,
                                    };
                                  })}
                                />
                              );
                            }
                            return null;
                          }}
                        </Fetch>
                      )}
                      {this.state.rtaReportingCodes &&
                        this.state.rtaReportingCodes.length > 0 && (
                          <Selectbox
                            defaultValue={this.state.reportingCode}
                            alignLabel="left"
                            label="Primary Reporting code"
                            required={false}
                            updateAction={(value) => {
                              this.setState({
                                reportingCode: value,
                              });
                            }}
                            options={[
                              { name: "Please choose", value: "" },
                              ...this.state.rtaReportingCodes.map((item) => {
                                return {
                                  name: item,
                                  value: item,
                                };
                              }),
                            ]}
                          />
                        )}
                      {this.state.rtaReportingCodes2 &&
                        this.state.rtaReportingCodes2.length > 0 && (
                          <Selectbox
                            defaultValue={this.state.reportingCode2}
                            alignLabel="left"
                            label="Secondary Reporting code"
                            required={false}
                            updateAction={(value) => {
                              this.setState({
                                reportingCode2: value,
                              });
                            }}
                            options={[
                              { name: "Please choose", value: "" },
                              ...this.state.rtaReportingCodes2.map((item) => {
                                return {
                                  name: item,
                                  value: item,
                                };
                              }),
                            ]}
                          />
                        )}

                      {this.state.rtaInterestedUser &&
                        this.state.rtaInterestedUser.showInterestedUsers ===
                          1 && (
                          <Textfield
                            defaultValue={this.state.interestedUser}
                            alignLabel="left"
                            label="Interested User"
                            required={false}
                            onChange={(value) => {
                              this.setState({
                                interestedUser: value,
                              });
                            }}
                          />
                        )}

                      {this.state.rtaReceivingNote &&
                        this.state.rtaReceivingNote.showReceivingNote === 1 && (
                          <Textfield
                            defaultValue={this.state.receivingNote}
                            alignLabel="left"
                            label="Receiving note"
                            type="textarea"
                            required={false}
                            style={{
                              marginLeft: "0",
                              minHeight: "90px",
                            }}
                            onChange={(value) => {
                              this.setState({
                                receivingNote: value,
                              });
                            }}
                          />
                        )}
                    </div>
                  )}
                  {showSpine && (
                    <div>
                      <hr />
                      <Checkbox
                        name={`spineLabel_${this.props.isbn}`}
                        onclick={(value) =>
                          this.setState({
                            spineLabel: value,
                          })
                        }
                        defaultChecked={
                          this.props.clientInfo.showspine == "1" ||
                          this.props.item.spine == "1"
                        }
                        label="Spine Label"
                      />
                      {showSpine && (
                        <div>
                          <Selectbox
                            alignLabel="top"
                            label="Color:"
                            defaultValue={this.props.item.spinecolor}
                            updateAction={(value) =>
                              this.setState({ color: value })
                            }
                            options={[
                              {
                                name: `White`,
                                value: `white`,
                              },
                              {
                                name: `Red`,
                                value: `red`,
                              },
                              {
                                name: `Blue`,
                                value: `blue`,
                              },
                              {
                                name: `Yellow`,
                                value: `yellow`,
                              },
                              {
                                name: `Green`,
                                value: `green`,
                              },
                            ]}
                          />
                          <Textfield
                            ref={(node) => (this._line1 = node)}
                            defaultValue={this.props.item.spineline1}
                            placeholder="Line 1"
                          />
                          <Textfield
                            ref={(node) => (this._line2 = node)}
                            defaultValue={this.props.item.spineline2}
                            placeholder="Line 2"
                          />
                          <Textfield
                            ref={(node) => (this._line3 = node)}
                            defaultValue={this.props.item.spineline3}
                            placeholder="Line 3"
                          />
                          <Textfield
                            ref={(node) => (this._line4 = node)}
                            defaultValue={this.props.item.spineline4}
                            placeholder="Line 4"
                          />
                          <Textfield
                            ref={(node) => (this._line5 = node)}
                            defaultValue={this.props.item.spineline5}
                            placeholder="Line 5"
                          />
                          <Textfield
                            ref={(node) => (this._line6 = node)}
                            defaultValue={this.props.item.spineline6}
                            placeholder="Line 6"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {showShelfReady && (
                    <div>
                      <hr />
                      <Checkbox
                        name={`shelfready_${this.props.isbn}`}
                        onclick={(value) =>
                          this.setState({
                            shelfready: value,
                          })
                        }
                        defaultChecked={showShelfReady}
                        label="Shelf-Ready"
                      />
                      {showShelfReadyDetails && (
                        <div>
                          <Textfield
                            type="textarea"
                            ref={(node) => (this._shelfReadyDetails = node)}
                            placeholder="Details"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="button-wrapper">
                    <Button
                      type="danger"
                      onClick={() =>
                        this.setState({
                          modalOpen: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button type="primary" onClick={this._submitUpdate}>
                      Update
                    </Button>
                  </div>
                </Modal>
                <img src="/images/icons/edit.png" alt="" />
              </button>
            ) : null}
            {this.props.item.editable ? (
              <button
                type="button"
                className="item-remove"
                onClick={this._onRemove}
              >
                <img src="/images/icons/delete.png" alt="" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

ShoppingCartItem.propTypes = {
  updateCart: React.PropTypes.func.isRequired,
  index: React.PropTypes.number.isRequired,
  isbn: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  price: React.PropTypes.number.isRequired,
  quantity: React.PropTypes.number.isRequired,
  onRemove: React.PropTypes.func.isRequired,
  onUpdateQuantity: React.PropTypes.func.isRequired,
  author: React.PropTypes.array,
  placeorder: React.PropTypes.string,
  konto: React.PropTypes.string,
  offerTextDescription: React.PropTypes.string,
  currency: React.PropTypes.string,
  budgetCode: React.PropTypes.string,
  reference: React.PropTypes.string,
  ebrary: React.PropTypes.string,
  item: React.PropTypes.object,
  rtaApiKey: React.PropTypes.string,
  fund: React.PropTypes.string,
  library: React.PropTypes.string,
  location: React.PropTypes.string,
  reportingCode: React.PropTypes.string,
  reportingCode2: React.PropTypes.string,
  interestedUser: React.PropTypes.string,
  receivingNote: React.PropTypes.string,
  clientInfo: React.PropTypes.shape({
    showspine: React.PropTypes.string,
    showshelfready: React.PropTypes.string,
    showshelfreadydetails: React.PropTypes.string,
  }),
};

ShoppingCartItem.defaultProps = {
  quantity: 1,
};

const mapStateToProps = (state) => {
  return {
    clientInfo: state.cart.clientInfo,
    rtaApiKey: state.cart.clientInfo.rta
      ? state.cart.clientInfo.rta.apikey
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (subaccount, index, item) => {
      dispatch(updateCart(subaccount, index, item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartItem);

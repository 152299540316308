import React from "react";

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

const App = props => {
  return (
    <div className="page-wrapper">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

App.propTypes = {
  children: React.PropTypes.node
};

App.defaultProps = {
  children: null
};

export default App;

import React from "react";
import Fetch from "./Fetch";
import { loginAPI } from "../settings";

const Greeter = () => {
  return (
    <Fetch
      url={loginAPI}
      data={{
        dataset: "profile",
      }}
    >
      {({ data }) =>
        data && (
          <div className="greeter">
            Welcome,{" "}
            <span className="greeter-name">
              {data[0].firstname} {data[0].lastname}
            </span>
            !
          </div>
        )
      }
    </Fetch>
  );
};

export default Greeter;

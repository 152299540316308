import actionTypes from '../constants/actionTypes';

export function clearBooks() {
  return {
    type: actionTypes.CLEAR_BOOKS
  };
}

export function addBook(data) {
  return {
    type: actionTypes.ADD_BOOK,
    data
  };
}

export function addCrawledBook(data) {
  return {
    type: actionTypes.ADD_CRAWLED_BOOK,
    data
  };
}

export function removeBook(isbn) {
  return {
    type: actionTypes.REMOVE_BOOK,
    isbn
  };
}

export function removeCrawledBook(isbn) {
  return {
    type: actionTypes.REMOVE_CRAWLED_BOOK,
    isbn
  };
}

export function showCrawledBooks() {
  return {
    type: actionTypes.SHOW_CRAWLED_BOOKS
  };
}

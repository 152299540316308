import React from "react";
import moment from "moment";
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker";

import "react-dates/lib/css/_datepicker.css";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  componentDidMount() {
    if (this.state.date) {
      this.props.onChange(this.state.date);
    }
  }

  _handleDateChange = (event, datepicker) => {
    const date = datepicker.startDate;
    if (date) {
      this.setState({
        date
      });
      this.props.onChange(date);
    }
  };

  render() {
    const date =
      this.state.date ||
      (this.props.defaultValue &&
        moment(this.props.defaultValue, "DD.MM.YYYY")) ||
      "";
    return (
      <div className={`textfield-wrapper`}>
        <DatetimeRangePicker
          startDate={date}
          endDate={date}
          onApply={this._handleDateChange}
          maxDate={moment()}
          singleDatePicker={true}
          showDropdowns={true}
        >
          <input
            ref={node => (this._input = node)}
            autoComplete="off"
            className={`textfield`}
            type={"text"}
            placeholder={this.props.placeholder}
            value={date.format("DD.MM.YYYY")}
          />
        </DatetimeRangePicker>
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  onChange: React.PropTypes.func,
  defaultValue: React.PropTypes.string,
  placeholder: React.PropTypes.string
};

export default DateRangePicker;

import React from "react";
import Searchform from "./Searchform";
import ShoppingCart from "../../components/shoppingCart/ShoppingCart";
import { browserHistory } from "react-router";
import queryString from "query-string";
import { baseUrl } from "../../settings";

class BookCatalogue extends React.Component {
  _handleSubmit = (data) => {
    const searchParams = queryString.stringify(data);
    browserHistory.push(`/catalogue/search/${searchParams}`);
  };

  render() {
    return (
      <div className="flex-container">
        <div>
          <Searchform onSubmit={this._handleSubmit} />
          {this.props.children ? (
            this.props.children
          ) : (
            <iframe
              src={`${baseUrl}/life/splashpage.php`}
              width="100%"
              height="800"
              frameBorder="0"
              scrolling="yes"
              marginHeight="0"
              marginWidth="0"
            />
          )}
        </div>
        <ShoppingCart />
      </div>
    );
  }
}

BookCatalogue.propTypes = {
  children: React.PropTypes.node,
};

BookCatalogue.defaultProps = {
  children: null,
};

export default BookCatalogue;

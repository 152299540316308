/* eslint-disable react/no-danger */
import React from "react";

const RenderHTML = props => {
  return <span dangerouslySetInnerHTML={{ __html: props.html }} />;
};

RenderHTML.propTypes = {
  html: React.PropTypes.string.isRequired
};

RenderHTML.defaultProps = {
  html: ""
};

export default RenderHTML;

import React from "react";
import moment from "moment";
import Fetch from "../../components/Fetch";
import reqwest from "reqwest";
import { Link } from "react-router";
import Cookies from "js-cookie";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import Button from "../../components/inputs/Button";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { loginAPI } from "../../settings";

class OrderDetail extends React.Component {
  _handleMessageSubmit = () => {
    const message = this._message._input.value;
    reqwest({
      url: loginAPI,
      data: {
        dataset: "message",
        orderno: this.props.params.parcelID,
        userlogin: Cookies.get("username"),
        sessionid: Cookies.get("sessionID"),
        message,
        punchoutid: Cookies.get("punchoutid") || null,
      },
      method: "get",
      crossOrigin: true,
      success: () => {
        window.location.reload();
      },
    });
  };

  render() {
    return (
      <div>
        <h2 className="PageTitle">
          Your Serial Order #{this.props.params.seriesID}
        </h2>
        <div className="OrderTrackingBooksDetail">
          <Fetch
            url={loginAPI}
            data={{
              dataset: "order",
              ordertype: "journals",
              orderno: this.props.params.orderID,
              fromdate: "01.01.1970",
              todate: moment().format("DD.MM.YYYY"),
            }}
          >
            {({ data: order, done: orderDone }) => {
              if (!orderDone) {
                return <Loader />;
              }
              return order && order.length ? (
                <Fetch
                  url={loginAPI}
                  data={{
                    dataset: "series",
                    orderno: this.props.params.orderID,
                    seriesid: this.props.params.seriesID,
                  }}
                >
                  {({ data: series, done: seriesDone }) => {
                    if (!seriesDone) {
                      return <Loader />;
                    }
                    return (
                      <div>
                        <div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Your order date:</div>
                            <div className="ValueContent">
                              {order[0].orderdate}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Title:</div>
                            <div className="ValueContent">
                              {order[0].journalorder[0].titel}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">ISSN:</div>
                            <div className="ValueContent">
                              {order[0].journalorder[0].issn}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Publisher:</div>
                            <div className="ValueContent">
                              {series && series[0] && series[0].publisher}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Subaccount:</div>
                            <div className="ValueContent">
                              {order[0].subaccount}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Budget Code:</div>
                            <div className="ValueContent">{order[0].code}</div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Quantity:</div>
                            <div className="ValueContent">
                              {order[0].quantity}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Your Reference:</div>
                            <div className="ValueContent">
                              {order[0].reference}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Order Id:</div>
                            <div className="ValueContent">
                              {order[0].journalorder[0].journalorderid}
                            </div>
                          </div>
                        </div>
                        {series && series[0] && series[0].item ? (
                          <Table
                            columns={[
                              "Series Key",
                              "Our Reference",
                              "Vol. / No.",
                              "Controltag",
                              "Expected / Shipped",
                            ]}
                          >
                            {series[0].item &&
                              series[0].item.map((item, index) => {
                                return (
                                  <Row key={index}>
                                    <Column>{series[0].seriesid}</Column>
                                    <Column>
                                      {order.length
                                        ? order[0].journalorder[0]
                                            .journalorderid
                                        : ""}
                                    </Column>
                                    <Column>
                                      {order.length ? (
                                        <Link
                                          to={`/orderDetailSeries/${order[0].orderno}/${item.ausgabename}`}
                                        >
                                          {item.ausgabename}
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </Column>
                                    <Column>{item.controltag}</Column>
                                    <Column>
                                      {item.controltag
                                        ? item.date
                                        : item.expecteddate}
                                    </Column>
                                  </Row>
                                );
                              })}
                          </Table>
                        ) : (
                          <Alert type="danger">
                            no publication dates available yet
                          </Alert>
                        )}
                      </div>
                    );
                  }}
                </Fetch>
              ) : (
                <Alert type="danger">no publication dates available yet</Alert>
              );
            }}
          </Fetch>
        </div>

        <br />
        <Button
          type="default"
          href="javascript:window.history.back(); void 0;"
          target=""
        >
          &lt; back
        </Button>
        <br />
      </div>
    );
  }
}

OrderDetail.propTypes = {
  params: React.PropTypes.object,
};

export default OrderDetail;

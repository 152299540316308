import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
// import createLogger from 'redux-logger';

export default function configureStore(initialState) {
  let store;
  let isProduction = process.env.NODE_ENV === "production";

  if (!isProduction) {
    // const logger = createLogger({
    //     level: 'log'
    // });
    if (window.devToolsExtension) {
      //Enable Redux devtools if the extension is installed in developer's browser
      //And the NODE_ENV is not in Production Mode
      store = createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(
            thunkMiddleware
            // logger
          ),
          window.devToolsExtension ? window.devToolsExtension() : f => f
        )
      );
    } else {
      //Enable Redux LOGGER if the NODE_ENV is not in Production Mode
      store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(
          thunkMiddleware
          // logger
        )
      );
    }
  } else {
    store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(thunkMiddleware)
    );
  }

  if (module.hot && !isProduction) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/rootReducer", () => {
      const nextReducer = require("../reducers/rootReducer").default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

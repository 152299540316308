let isProduction = process.env.NODE_ENV === "production";

let baseUrl = "";
if (!isProduction) {
  baseUrl = "//127.0.0.1:8080/booksearch.delbanco.de";
} else {
  baseUrl = "//booksearch.delbanco.de";
}

export const loginAPI = baseUrl + "/life/loginapi.php";
export const searchAPI = baseUrl + "/life/searchapi2.php";
export const adminPanelAPI = baseUrl + "/life/adminpanel.php";
export const bakScarterAPI = baseUrl + "/life/bak.scarter.php";
export const searchAPINo2 = baseUrl + "/life/searchapi.php";

export { baseUrl };

import React from "react";
import Fetch from "../../components/Fetch";
import { Link } from "react-router";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import reqwest from "reqwest";
import Cookies from "js-cookie";
import moment from "moment";
import Checkbox from "../../components/inputs/Checkbox";
import Loader from "../../components/Loader";
import { loginAPI } from "../../settings";

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendCopy: true,
    };
  }

  _handleMessageSubmit = () => {
    const message = this._message._input.value;
    reqwest({
      url: loginAPI,
      data: {
        dataset: "message",
        orderno: this.props.params.orderID,
        userlogin: Cookies.get("username"),
        sessionid: Cookies.get("sessionID"),
        copy: this.state.sendCopy ? "1" : "",
        message,
        punchoutid: Cookies.get("punchoutid") || null,
      },
      method: "get",
      crossOrigin: true,
      success: () => {
        window.location.reload();
      },
    });
  };

  render() {
    return (
      <div>
        <h2 className="PageTitle">
          Your Book Order #{this.props.params.orderID}
        </h2>
        <div className="OrderTrackingBooksDetail">
          <Fetch
            url={loginAPI}
            data={{
              dataset: "order",
              ordertype: "book",
              orderno: this.props.params.orderID,
              fromdate: "01.01.1970",
              todate: moment().format("DD.MM.YYYY"),
              subaccount: "",
            }}
          >
            {({ data: order }) =>
              order && order.length > 0 ? (
                <div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Subaccount:</div>
                    <div className="ValueContent">
                      {order[0].subaccount} {order[0].name1} {order[0].name2}
                    </div>
                  </div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Your order date:</div>
                    <div className="ValueContent">{order[0].orderdate}</div>
                  </div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Budget Code:</div>
                    <div className="ValueContent">{order[0].code}</div>
                  </div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Quantity:</div>
                    <div className="ValueContent">{order[0].quantity}</div>
                  </div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Your Reference:</div>
                    <div className="ValueContent">{order[0].reference}</div>
                  </div>
                  <div className="ValueRow">
                    <div className="ValueTitle">Urgent:</div>
                    <div className="ValueContent">
                      {order[0].urgent == 0 ? "no" : "yes"}
                    </div>
                  </div>
                  <hr />
                  <Fetch
                    url={loginAPI}
                    data={{
                      dataset: "book",
                      wareid: order[0].bookorder[0].wareid,
                      subaccount: order[0].subaccount,
                    }}
                  >
                    {({ data: book }) =>
                      book ? (
                        <div>
                          <div className="ValueRow">
                            <div className="ValueTitle">ISBN:</div>
                            <div className="ValueContent">{book[0].isbn}</div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Title:</div>
                            <div className="ValueContent">{book[0].title}</div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Author:</div>
                            <div className="ValueContent">{book[0].author}</div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Binding:</div>
                            <div className="ValueContent">
                              {book[0].binding}
                            </div>
                          </div>
                          <div className="ValueRow">
                            <div className="ValueTitle">Publisher:</div>
                            <div className="ValueContent">
                              {book[0].publisher}
                            </div>
                          </div>
                          {order[0].bookorder[0].fixprice && (
                            <div className="ValueRow">
                              <div className="ValueTitle">Fix-price:</div>
                              <div className="ValueContent">
                                {order[0].bookorder[0].fixprice}{" "}
                                {order[0].bookorder[0].fixpricecurrency}
                              </div>
                            </div>
                          )}
                          <hr />
                          <Fetch
                            url={loginAPI}
                            data={{
                              dataset: "orderdetail",
                              orderno: this.props.params.orderID,
                              wareid: order[0].bookorder[0].wareid,
                              subaccount: order[0].subaccount,
                            }}
                          >
                            {({ data: orderdetail }) =>
                              orderdetail ? (
                                <div>
                                  <div
                                    className="OrderDetail-table"
                                    dangerouslySetInnerHTML={{
                                      __html: orderdetail[0].orderhistory,
                                    }}
                                  />
                                  {orderdetail[0] &&
                                    orderdetail[0].controltags &&
                                    orderdetail[0].controltags.map(
                                      (controltag, ci) => {
                                        return (
                                          <div key={ci}>
                                            <hr />
                                            {controltag.invoices &&
                                              controltag.invoices.map(
                                                (invoice, ii) => {
                                                  return (
                                                    invoice.id && (
                                                      <div key={ii}>
                                                        <a
                                                          target="_blank"
                                                          href={`${loginAPI}?dataset=invoice&userlogin=${Cookies.get(
                                                            "username"
                                                          )}&allsubaccounts=${
                                                            Cookies.get(
                                                              "mainaccounts"
                                                            ) &&
                                                            Cookies.get(
                                                              "mainaccounts"
                                                            ).indexOf("ALL") !=
                                                              -1
                                                              ? "ALL"
                                                              : Cookies.get(
                                                                  "allsubaccounts"
                                                                )
                                                          }&subaccount=${
                                                            order[0].subaccount
                                                          }&sessionid=${Cookies.get(
                                                            "sessionID"
                                                          )}&fromdate=01.01.1970&todate=${moment().format(
                                                            "DD.MM.YYYY"
                                                          )}&invoiceid=${
                                                            invoice.id
                                                          }&type=${
                                                            invoice.type
                                                          }&pdf=1`}
                                                        >
                                                          Content of Invoice (#
                                                          {invoice.id})
                                                        </a>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              )}
                                            {controltag.parcels &&
                                              controltag.parcels.map(
                                                (parcel, ip) => {
                                                  return (
                                                    parcel.parcelid && (
                                                      <div key={ip}>
                                                        <Link
                                                          to={`/parcelsDetail/${parcel.parcelid}`}
                                                        >
                                                          Content of Parcel (#
                                                          {parcel.parcelid})
                                                        </Link>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              ) : (
                                <Loader />
                              )
                            }
                          </Fetch>
                        </div>
                      ) : (
                        <Loader />
                      )
                    }
                  </Fetch>
                </div>
              ) : (
                <Loader />
              )
            }
          </Fetch>
          <hr />
          <div className="CommentForm">
            <Textfield
              type="textarea"
              ref={(node) => (this._message = node)}
              placeholder="Your Comment..."
            />
            <Checkbox
              label="Send a copy to me"
              defaultChecked={true}
              onclick={(value) => this.setState({ sendCopy: value })}
              name="sendCopy"
            />
            <Button type="primary" onClick={this._handleMessageSubmit}>
              Send your Comment
            </Button>
          </div>
        </div>
        <br />
        <Button
          type="default"
          href="javascript:window.history.back(); void 0;"
          target=""
        >
          &lt; back
        </Button>
        <br />
      </div>
    );
  }
}

OrderDetail.propTypes = {
  params: React.PropTypes.object,
};

export default OrderDetail;

import React from "react";
import { AllHtmlEntities } from "html-entities";
import equal from "deep-equal";

const entities = new AllHtmlEntities();

class Selectbox extends React.Component {
  static propTypes = {
    alignLabel: React.PropTypes.oneOf(["top", "right", "left", "none"])
      .isRequired,
    name: React.PropTypes.string,
    label: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    placeholder: React.PropTypes.string,
    options: React.PropTypes.array.isRequired,
    initialValue: React.PropTypes.string,
    defaultValue: React.PropTypes.string,
    tabIndex: React.PropTypes.number,
    className: React.PropTypes.string,
    updateAction: React.PropTypes.func,
  };

  static defaultProps = {
    tabIndex: 1,
  };

  componentDidMount() {
    const value = this._selectbox.value;
    this.props.updateAction && this.props.updateAction(value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(this.props, nextProps) || !equal(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.options, prevProps.options)) {
      const value = this._selectbox.value;
      this.props.updateAction && this.props.updateAction(value);
    }
  }

  _onChange = () => {
    this.setState({
      validate: true,
    });
    const value = this._selectbox.value;
    this.props.updateAction(value);
  };

  render() {
    return this.props.alignLabel == "left" ? (
      <div
        className={`selectbox-wrapper ${this.props.alignLabel} ${this.props.className}`}
      >
        <label
          className={`selectbox-label ${this.props.alignLabel}`}
          htmlFor={this.props.name}
        >
          {entities.decode(this.props.label)}
        </label>
        <div className="selectbox-input-wrapper">
          <select
            ref={(node) => (this._selectbox = node)}
            onChange={this._onChange}
            className={`selectbox`}
            name={this.props.name}
            tabIndex={this.props.tabIndex}
            placeholder={this.props.placeholder}
            value={this.props.value || this.props.defaultValue}
          >
            {this.props.options.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {entities.decode(option.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    ) : this.props.alignLabel == "top" ? (
      <div className={`selectbox-wrapper`}>
        <label
          className={`selectbox-label ${this.props.alignLabel}`}
          htmlFor={this.props.name}
        >
          {entities.decode(this.props.label)}
        </label>
        <br />
        <div className="selectbox-input-wrapper">
          <select
            ref={(node) => (this._selectbox = node)}
            onChange={this._onChange}
            className={`selectbox`}
            name={this.props.name}
            tabIndex={this.props.tabIndex}
            placeholder={this.props.placeholder}
            value={this.props.value || this.props.defaultValue}
          >
            {this.props.options.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {entities.decode(option.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    ) : this.props.alignLabel == "right" ? (
      <div className={`selectbox-wrapper`}>
        <div className="selectbox-input-wrapper">
          <select
            ref={(node) => (this._selectbox = node)}
            onChange={this._onChange}
            className={`selectbox`}
            name={this.props.name}
            tabIndex={this.props.tabIndex}
            placeholder={this.props.placeholder}
            value={this.props.value || this.props.defaultValue}
          >
            {this.props.options.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {entities.decode(option.content.body)}
                </option>
              );
            })}
          </select>
        </div>
        <label
          className={`selectbox-label ${this.props.alignLabel}`}
          htmlFor={this.props.name}
        >
          {entities.decode(this.props.label)}
        </label>
      </div>
    ) : this.props.alignLabel == "none" ? (
      <div className={`selectbox-wrapper`}>
        <div className="selectbox-input-wrapper">
          <select
            ref={(node) => (this._selectbox = node)}
            onChange={this._onChange}
            className={`selectbox`}
            name={this.props.name}
            tabIndex={this.props.tabIndex}
            placeholder={this.props.placeholder}
            value={this.props.value || this.props.defaultValue}
          >
            {this.props.options.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {entities.decode(option.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    ) : (
      <div>alignLabel is not defined</div>
    );
  }
}

export default Selectbox;

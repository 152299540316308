import actionTypes from "../constants/actionTypes";
import reqwest from "reqwest";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { bakScarterAPI } from "../settings";

const testing = "0";

export function getInitialCart() {
  return function (dispatch, getState) {
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        if (d.scart) {
          dispatch({
            type: actionTypes.CART_UPDATE_FROM_SERVER,
            data: d.scart,
          });
        }
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

export function addToCart(subaccount, data) {
  return function (dispatch, getState) {
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        callbackparameter: "add",
        scart: {
          knut: subaccount,
          copy: "1",
          ...data,
        },
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        dispatch({
          type: actionTypes.CART_UPDATE_FROM_SERVER,
          data: d.scart,
        });
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }

        swal({
          title: "Success!",
          text: `Your item was added to the cart.

          ${d.successMessage ? d.successMessage : ""}`,
          showConfirmButton: d.successMessage ? true : false,
          timer: d.successMessage ? null : 1200,
          type: "success",
          allowOutsideClick: true,
        });
      },
    });
  };
}

export function updateQuantity(subaccount, item, quantity) {
  return function (dispatch, getState) {
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        callbackparameter: "update",
        item,
        copy: quantity,
        scart: {
          knut: subaccount,
        },
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        dispatch({
          type: actionTypes.CART_UPDATE_FROM_SERVER,
          data: d.scart,
        });
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

export function updateCart(subaccount, index, item) {
  return function (dispatch, getState) {
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        callbackparameter: "edit_update",
        item: index,
        scart: item,
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        dispatch({
          type: actionTypes.CART_UPDATE_FROM_SERVER,
          data: d.scart,
        });
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

export function removeFromCart(item) {
  return function (dispatch, getState) {
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        callbackparameter: "delete",
        item,
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        dispatch({
          type: actionTypes.CART_UPDATE_FROM_SERVER,
          data: d.scart,
        });
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

export function orderCart() {
  return function (dispatch, getState) {
    const cartURL = Cookies.get("cartURL");
    const currentState = getState();
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });

    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: currentState.cart.selectedCart,
        callbackparameter: "order",
        punchout: cartURL ? "1" : "0",
        purl: cartURL,
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        swal({
          title: "Success!",
          text: "Thank you for your order",
          showConfirmButton: true,
          type: "success",
          allowOutsideClick: false,
        });
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        if (cartURL) {
          document.body.innerHTML = d;
          document.getElementById("punchoutForm").submit();
          return;
        }

        d = JSON.parse(d + "");
        if (d.scart) {
          dispatch({
            type: actionTypes.CART_UPDATE_FROM_SERVER,
            data: d.scart,
          });
        }
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

export function setSelectedCart(cart) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CART_LOADING,
      data: true,
    });
    return reqwest({
      url: bakScarterAPI,
      method: "GET",
      crossOrigin: true,
      data: {
        wwplognam: Cookies.get("username"),
        wwppasswd: Cookies.get("password"),
        selectedScart: cart,
        testing,
        pj: "1",
        punchoutid: Cookies.get("punchoutid") || null,
      },
      error: (err) => {
        console.error(err);
        swal({
          title: "Something went wrong!",
          text: "Please try again in a few minutes...",
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
      },
      success: (d) => {
        d = JSON.parse(d + "");
        dispatch({
          type: actionTypes.CART_LOADING,
          data: false,
        });
        localStorage.setItem("selectedCart", cart);
        if (d.scart) {
          dispatch({
            type: actionTypes.CART_UPDATE_FROM_SERVER,
            data: d.scart,
          });
        }
        if (d.clientInfo) {
          dispatch({
            type: actionTypes.CART_UPDATE_CLIENT_INFO,
            data: d.clientInfo,
          });
        }
        if (d.availableScarts) {
          dispatch({
            type: actionTypes.CART_UPDATE_AVAILABLE_CARTS,
            data: d.availableScarts,
          });
        }
        if (d.selectedScart) {
          dispatch({
            type: actionTypes.CART_UPDATE_SELECTED_CART,
            data: d.selectedScart,
          });
        }
      },
    });
  };
}

import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import bookReducer from "./bookReducer";
import crawledBookReducer from "./crawledBookReducer";

const rootReducer = combineReducers({
  cart: cartReducer,
  books: bookReducer,
  crawledBooks: crawledBookReducer
});

export default rootReducer;

import React from "react";
import NavigationItem from "./NavigationItem";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
  }
  _renderChildNodes(nodes) {
    const allPaths = nodes;
    return allPaths.map((item, index) => {
      return (
        <NavigationItem
          key={index}
          path={item.path}
          render={item.render}
          name={item.name}
        >
          {item.nodes ? (
            <ul>
              {/* RECURSION!!! */}
              {this._renderChildNodes(item.nodes)}
            </ul>
          ) : null}
        </NavigationItem>
      );
    });
  }
  render() {
    const nodes = this._renderChildNodes(this.props.paths.nodes);
    return <nav className="navigation">{nodes}</nav>;
  }
}

Navigation.propTypes = {
  paths: React.PropTypes.object
};

Navigation.defaultProps = {
  paths: {
    nodes: []
  }
};

export default Navigation;

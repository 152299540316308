import React from "react";
import RenderHTML from "../RenderHTML";
import equal from "deep-equal";

class Checkbox extends React.Component {
  static propTypes = {
    onclick: React.PropTypes.func,
    label: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    className: React.PropTypes.string,
    value: React.PropTypes.string,
    defaultChecked: React.PropTypes.bool,
    required: React.PropTypes.bool,
    checked: React.PropTypes.bool,
    onInfoClick: React.PropTypes.func,
    tabIndex: React.PropTypes.number
  };

  static defaultProps = {
    name: "checkbox",
    tabIndex: 1
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(this.props, nextProps) || !equal(this.state, nextState);
  }

  _handleClick = event => {
    event.stopPropagation();
    if (typeof this.props.onclick === "function") {
      let value = this._input.checked || false;
      if (value && this.props.value) {
        value = this.props.value;
      }
      this.props.onclick(value);
    }
  };

  render() {
    return (
      <div className="checkbox-wrapper">
        <input
          onChange={this._handleClick}
          className={`checkbox ${this.props.className}`}
          type="checkbox"
          ref={node => (this._input = node)}
          id={this.props.name}
          name={this.props.name}
          tabIndex={this.props.tabIndex}
          value={this.props.value}
          defaultChecked={this.props.defaultChecked}
          checked={this.props.checked}
        />
        <label className="checkbox-label" htmlFor={this.props.name}>
          <RenderHTML html={`${this.props.label}`} />
        </label>
      </div>
    );
  }
}

export default Checkbox;

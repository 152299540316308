import React from "react";
import Cookies from "js-cookie";
import moment from "moment";
import Checkbox from "../../components/inputs/Checkbox";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import Fetch from "../../components/Fetch";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import DateRangePicker from "../../components/inputs/DateRangePicker";
import Selectbox from "../../components/inputs/Selectbox";
import Loader from "../../components/Loader";

import { browserHistory } from "react-router";
import queryString from "query-string";
import { loginAPI } from "../../settings";

class OrderTrackingBooks extends React.Component {
  static propTypes = {
    params: React.PropTypes.object,
  };

  constructor(props) {
    super(props);

    this._isAdmin = Cookies.get("mainaccounts").indexOf("ALL") >= 0;

    const searchParams = queryString.parse(props.params.searchparams);

    this.state = {
      subaccount: Cookies.get("subaccount") ? Cookies.get("subaccount") : "",
      allsubaccounts: this._isAdmin ? "ALL" : Cookies.get("allsubaccounts"),
      fromdate: moment().subtract(3, "months").format("DD.MM.YYYY"),
      todate: moment().format("DD.MM.YYYY"),
      ...searchParams,
    };
  }

  _handleDateOnChangeFrom = (orderdate) => {
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      fromdate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/tracking-books/${searchParams}`);
  };

  _handleDateOnChangeTo = (orderdate) => {
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      todate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/tracking-books/${searchParams}`);
  };

  _setErledigt = (value) => {
    if (value) {
      value = 0;
    } else {
      value = 1;
    }
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      erledigt: value,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/tracking-books/${searchParams}`);
  };

  _setEbooks = (value) => {
    if (value) {
      value = 1;
    } else {
      value = 0;
    }
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      eonly: value,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/tracking-books/${searchParams}`);
  };

  _handleSubmit = (event) => {
    event.preventDefault();

    if (!this._form.reportValidity()) {
      return;
    }

    const sp = queryString.parse(this.props.params.searchparams);

    let subaccount = Cookies.get("subaccount");
    if (this._subaccount && this._subaccount._input) {
      subaccount = this._subaccount._input.value;
    } else if (sp && sp.subaccount !== undefined) {
      subaccount = sp.subaccount;
    }
    const reference = this._reference._input.value;
    const code = this._code._input.value;
    const orderno = this._orderno._input.value;
    const title = this._title._input.value;
    const isbn = this._isbn._input.value;
    const author = this._author._input.value;

    const query = {
      ...this.state,
      ...sp,
      subaccount,
      reference,
      code,
      orderno,
      title,
      isbn,
      author,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/tracking-books/${searchParams}`);
    return false;
  };

  render() {
    const searchParams = queryString.parse(this.props.params.searchparams);
    const state = {
      ...this.state,
      ...searchParams,
    };
    return (
      <div>
        <form
          className="search-form"
          ref={(node) => (this._form = node)}
          onSubmit={this._handleSubmit}
        >
          <div className="row">
            {Cookies.get("mainaccounts").indexOf("ALL") == -1 ? (
              <Fetch
                url={loginAPI}
                data={{
                  dataset: "profile",
                }}
              >
                {({ data }) =>
                  data ? (
                    <Selectbox
                      alignLabel="none"
                      label=""
                      defaultValue={state.subaccount}
                      updateAction={(value) => {
                        const query = {
                          ...state,
                          subaccount: value,
                        };
                        const searchParams = queryString.stringify(query);
                        browserHistory.push(`/tracking-books/${searchParams}`);
                      }}
                      options={[
                        {
                          name: "All Subaccounts",
                          value: "",
                        },
                        ...(data[0].subaccounts &&
                          data[0].subaccounts.map((item) => {
                            return {
                              name: item.account,
                              value: item.account,
                            };
                          })),
                      ]}
                    />
                  ) : null
                }
              </Fetch>
            ) : (
              <Textfield
                ref={(node) => (this._subaccount = node)}
                placeholder={`Subaccount (default: ${state.subaccount})`}
              />
            )}
            <Textfield
              ref={(node) => (this._reference = node)}
              placeholder="Your reference"
            />
            <Textfield
              ref={(node) => (this._code = node)}
              placeholder="Budget code"
              autocompleteBudgetCode={state.subaccount}
            />
            <Textfield
              ref={(node) => (this._orderno = node)}
              placeholder="Delbanco order no."
            />
          </div>
          <div className="row">
            <Textfield
              ref={(node) => (this._title = node)}
              placeholder="Title"
            />
            <Textfield ref={(node) => (this._isbn = node)} placeholder="ISBN" />
            <Textfield
              ref={(node) => (this._author = node)}
              placeholder="Author"
            />
          </div>
          <div className="row">
            <DateRangePicker
              defaultValue={state.fromdate}
              placeholder="Your order date (from)"
              onChange={this._handleDateOnChangeFrom}
            />
            <DateRangePicker
              defaultValue={state.todate}
              placeholder="Your order date (to)"
              onChange={this._handleDateOnChangeTo}
            />
          </div>
          <div className="row">
            <Checkbox
              label="Only orders open for supply"
              onclick={this._setErledigt}
              name="erledigt"
            />
            <Checkbox
              label="Only E-Books"
              onclick={this._setEbooks}
              name="ebooks"
            />
            <Button type="primary" onClick={this._handleSubmit}>
              Search
            </Button>
          </div>
        </form>
        <div className="OrderTrackingBooks">
          <h2 className="PageTitle">Your Book / Ebook Orders</h2>
          <Alert>
            <div className="legend not-inserted">
              Not yet inserted orders - You can check the order status in due
              course.
            </div>
          </Alert>
          <Fetch
            url={loginAPI}
            data={{
              dataset: "order",
              ordertype: "book",
              userlogin: Cookies.get("username"),
              subaccount: state.subaccount,
              allsubaccounts: state.allsubaccounts,
              reference: state.reference,
              code: state.code,
              orderno: state.orderno,
              title: state.title,
              isbn: state.isbn,
              author: state.author,
              erledigt: state.erledigt,
              eonly: state.eonly,
              fromdate: state.fromdate,
              todate: state.todate,
              operator: ">=",
            }}
          >
            {({ data, done }) =>
              done ? (
                data && data.length > 0 ? (
                  <div>
                    <br />
                    <Button
                      type="default"
                      target="_blank"
                      href={`${loginAPI}?csv=1&dataset=order&ordertype=book&userlogin=${Cookies.get(
                        "username"
                      )}${
                        state.subaccount
                          ? `&subaccount=${state.subaccount}`
                          : ""
                      }${
                        state.allsubaccounts
                          ? `&allsubaccounts=${state.allsubaccounts}`
                          : ""
                      }${
                        state.reference ? `&reference=${state.reference}` : ""
                      }${state.code ? `&code=${state.code}` : ""}${
                        state.orderno ? `&orderno=${state.orderno}` : ""
                      }${state.title ? `&title=${state.title}` : ""}${
                        state.isbn ? `&isbn=${state.isbn}` : ""
                      }${state.author ? `&author=${state.author}` : ""}${
                        state.erledigt ? `&erledigt=${state.erledigt}` : ""
                      }${state.eonly ? `&eonly=${state.eonly}` : ""}${
                        state.fromdate ? `&fromdate=${state.fromdate}` : ""
                      }${
                        state.todate ? `&todate=${state.todate}` : ""
                      }&operator=${">="}${
                        Cookies.get("username")
                          ? `&userlogin=${Cookies.get("username")}`
                          : ""
                      }${
                        Cookies.get("sessionID")
                          ? `&sessionid=${Cookies.get("sessionID")}`
                          : ""
                      }${
                        Cookies.get("subaccount")
                          ? `&subaccount=${Cookies.get("subaccount")}`
                          : ""
                      }${
                        Cookies.get("punchoutid")
                          ? `&punchoutid=${Cookies.get("punchoutid")}`
                          : ""
                      }`}
                    >
                      Export results as CSV
                    </Button>
                    <br />
                    <br />
                    <Table
                      columns={[
                        "Delbanco order no.",
                        "Order date",
                        "Your reference",
                        "ISBN",
                        "Title",
                        "",
                      ]}
                    >
                      {data.map((order, index) => {
                        return (
                          order.bookorder &&
                          order.bookorder.map((book) => {
                            return (
                              <Row
                                key={index}
                                className={`${
                                  order.orderno == "0" ? "not-inserted" : ""
                                }`}
                              >
                                <Column>{order.orderno}</Column>
                                <Column>{order.orderdate}</Column>
                                <Column>{order.reference}</Column>
                                <Column>{book.isbn}</Column>
                                <Column align="left">{book.titel}</Column>
                                <Column>
                                  {order.orderno != "0" && (
                                    <Button
                                      type="default"
                                      to={`/orderDetail/${order.orderno}`}
                                    >
                                      Details
                                    </Button>
                                  )}
                                </Column>
                              </Row>
                            );
                          })
                        );
                      })}
                    </Table>
                  </div>
                ) : (
                  <Alert type="danger">
                    No matches found... <br />
                    Please check your search parameters.
                  </Alert>
                )
              ) : (
                <Loader />
              )
            }
          </Fetch>
        </div>
      </div>
    );
  }
}

export default OrderTrackingBooks;

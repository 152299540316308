import actionTypes from "../constants/actionTypes";

const initialState = [];

export default function bookReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_BOOK: {
      const isInStore = state.some(
        (storeBook) =>
          action.data.volumeInfo.isbn13s === storeBook.volumeInfo.isbn13s
      );
      if (!isInStore) {
        if (action.data.crawledAddition) {
          return [action.data, ...state];
        } else {
          return [...state, action.data];
        }
      } else {
        return state;
      }
    }
    case actionTypes.REMOVE_BOOK:
      return state.filter((book) => {
        book.volumeInfo.isbn13s !== action.isbn;
      });
    case actionTypes.CLEAR_BOOKS:
      return initialState;
    default:
      return state;
  }
}

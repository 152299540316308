import React from "react";
import moment from "moment";
import Fetch from "../../components/Fetch";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import Button from "../../components/inputs/Button";
import Loader from "../../components/Loader";
import { Link } from "react-router";
import { loginAPI } from "../../settings";

const Parcels = (props) => {
  return (
    <div>
      <div className="Parcels">
        <h2 className="PageTitle">Your Parcel #{props.params.parcelID}</h2>
        <Fetch
          url={loginAPI}
          data={{
            dataset: "parcels",
            parcelid: props.params.parcelID,
            fromdate: "01.01.1990",
            todate: moment().format("DD.MM.YYYY"),
            withitem: "1",
          }}
        >
          {({ data, done }) =>
            done ? (
              data ? (
                <Table
                  columns={[
                    "Parcel Number",
                    "Sending Date",
                    "Shipping Partner",
                    "Shipping Method",
                    "UPS tracking",
                  ]}
                >
                  {data.map((item, index) => {
                    return (
                      <Row key={index}>
                        <Column>{item.parcelid}</Column>
                        <Column>{item.senddate}</Column>
                        <Column>{item.shippingprtner}</Column>
                        <Column>{item.shippingmethod}</Column>
                        <Column>
                          <a
                            target="_blank"
                            href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${item.trackingid}`}
                          >
                            {item.trackingid}
                          </a>
                        </Column>
                      </Row>
                    );
                  })}
                  <div className="ValueRow">
                    <div className="ValueTitle">Weight:</div>
                    <div className="ValueContent">{data[0].weight}</div>
                  </div>
                  {data[0].item.map((item, key) => {
                    return (
                      <div key={key}>
                        <br />
                        <div className="ValueRow">
                          <div className="ValueTitle">Controltag:</div>
                          <div className="ValueContent">
                            <Link to={`/orderDetail/${item.orderno}`}>
                              {item.controltag}
                            </Link>
                          </div>
                        </div>
                        <div className="ValueRow">
                          <div className="ValueTitle">ISBN / ISSN:</div>
                          <div className="ValueContent">{item.isxn}</div>
                        </div>
                        <div className="ValueRow">
                          <div className="ValueTitle">Title:</div>
                          <div className="ValueContent">{item.title}</div>
                        </div>
                        <div className="ValueRow">
                          <div className="ValueTitle">Author:</div>
                          <div className="ValueContent">{item.other}</div>
                        </div>
                      </div>
                    );
                  })}
                </Table>
              ) : (
                <Alert type="danger">
                  No matches found... <br />
                  Please check your search parameters.
                </Alert>
              )
            ) : (
              <Loader />
            )
          }
        </Fetch>
      </div>
      <br />
      <Button
        type="default"
        href="javascript:window.history.back(); void 0;"
        target=""
      >
        &lt; back
      </Button>
      <br />
    </div>
  );
};

Parcels.propTypes = {
  params: React.PropTypes.object,
};

export default Parcels;

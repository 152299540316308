import React from "react";
import Cookies from "js-cookie";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import Fetch from "../../components/Fetch";
import reqwest from "reqwest";
import Loader from "../../components/Loader";
import { adminPanelAPI, loginAPI } from "../../settings";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
    };
  }

  _setDefaultSubaccount = (account) => {
    reqwest({
      url: loginAPI,
      data: {
        dataset: "profile",
        defaultsubaccount: account,
        userlogin: Cookies.get("username"),
        sessionid: Cookies.get("sessionID"),
        punchoutid: Cookies.get("punchoutid") || null,
      },
      method: "get",
      crossOrigin: true,
      success: () => {
        Cookies.set("subaccount", account);
      },
    });
  };

  _saveChanges = () => {
    const firstname = this._firstname._input.value;
    const lastname = this._lastname._input.value;
    const telefon = this._telefon._input.value;
    const fax = this._fax._input.value;
    const email = this._email._input.value;
    const birthday = this._birthday._input.value;
    reqwest({
      url: loginAPI,
      data: {
        dataset: "user",
        updatedata: "1",
        firstname,
        lastname,
        telefon,
        fax,
        email,
        birthday,
        userlogin: Cookies.get("username"),
        sessionid: Cookies.get("sessionID"),
        punchoutid: Cookies.get("punchoutid") || null,
      },
      method: "get",
      crossOrigin: true,
      success: () => {
        window.location.reload();
      },
    });
  };

  render() {
    return (
      <Fetch
        url={loginAPI}
        data={{
          dataset: "profile",
        }}
      >
        {({ data }) =>
          data ? (
            <div className="Profile">
              <h2 className="PageTitle">Your Personal Profile</h2>
              <div className="Profile-data">
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._firstname = node)}
                    label="First Name"
                    defaultValue={data[0].firstname}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._lastname = node)}
                    label="Last Name"
                    defaultValue={data[0].lastname}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._telefon = node)}
                    label="Telephone"
                    defaultValue={data[0].telefon}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._fax = node)}
                    label="Fax"
                    defaultValue={data[0].fax}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._email = node)}
                    label="E-Mail"
                    defaultValue={data[0].email}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    ref={(node) => (this._birthday = node)}
                    label="Birthday"
                    defaultValue={data[0].birthday}
                  />
                </div>
                <div className="Profile-info">
                  <Textfield
                    disabled={true}
                    label="Main account"
                    value={
                      data[0].mainaccounts &&
                      data[0].mainaccounts
                        .map(
                          (item, index) =>
                            `${index > 0 ? ", " : ""}${item.account}`
                        )
                        .join(",")
                    }
                  />
                </div>
                <div className="Profile-subaccount">
                  <div className="Profile-subaccountLabel">
                    Default Sub-Account:
                  </div>
                  {data[0].subaccounts &&
                    data[0].subaccounts.map((item, index) => {
                      return (
                        <label
                          htmlFor={`subaccount_${item.account}`}
                          key={index}
                        >
                          <input
                            defaultChecked={
                              data[0].defaultsubaccount == item.account
                            }
                            id={`subaccount_${item.account}`}
                            type="radio"
                            name="defaultsubaccount"
                            onChange={this._setDefaultSubaccount.bind(
                              this,
                              item.account
                            )}
                          />
                          <span>{item.account}</span>
                          <span>{item.name && item.name}</span>
                        </label>
                      );
                    })}
                </div>
              </div>
              <br />
              <Button type="primary" onClick={this._saveChanges}>
                Save changes
              </Button>
              {data[0].showAdminPanel == "1" ? (
                <Button
                  type="default"
                  target="_blank"
                  href={`https:${adminPanelAPI}?username=${Cookies.get(
                    "username"
                  )}&sessionid=${Cookies.get("sessionID")}`}
                >
                  Admin Panel
                </Button>
              ) : null}
            </div>
          ) : (
            <Loader />
          )
        }
      </Fetch>
    );
  }
}

export default Profile;

import actionTypes from "../constants/actionTypes";

const initialState = {
  cart: [],
  clientInfo: {},
  availableCarts: [],
  selectedCart: localStorage.getItem("selectedCart") || "",
  loading: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CART_LOADING:
      return Object.assign({}, state, {
        loading: action.data === true ? state.loading + 1 : state.loading - 1,
      });
    case actionTypes.CART_UPDATE_FROM_SERVER:
      return Object.assign({}, state, {
        cart: action.data,
      });
    case actionTypes.CART_UPDATE_CLIENT_INFO:
      return Object.assign({}, state, {
        clientInfo: action.data,
      });
    case actionTypes.CART_UPDATE_AVAILABLE_CARTS:
      return Object.assign({}, state, {
        availableCarts: action.data,
      });
    case actionTypes.CART_UPDATE_SELECTED_CART:
      return Object.assign({}, state, {
        selectedCart: action.data,
      });
    default:
      return state;
  }
}

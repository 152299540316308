import React from "react";
import Button from "../inputs/Button";
import Logo from "../Logo";
import Cookies from "js-cookie";

const Header = () => {
  return (
    <div className="header">
      <Logo />
      <div>
        {Cookies.get("sessionID") && <Button to="/logout">Logout</Button>}
      </div>
    </div>
  );
};

export default Header;

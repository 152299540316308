import React from "react";
import ResultItem from "./SearchResultItem";
import { connect } from "react-redux";

class ResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: false,
    };
  }
  componentWillReceiveProps() {
    this.setState({
      loadMore: false,
    });
  }
  render() {
    const { books } = this.props;

    return (
      <div>
        <h2 className="search-results-number">
          {this.props.totalResults} Results
        </h2>
        {books.map((book) => {
          return (
            <ResultItem
              key={`${book.volumeInfo.isbn13s}`}
              data={{ clientInfo: books[0].clientInfo || {}, ...book }}
            />
          );
        })}
        {this.props.totalResults > this.props.books.length ? (
          <button
            className="search-results-more"
            onClick={() => {
              this.props.onLoadMore();
            }}
          >
            Load more...
          </button>
        ) : null}
      </div>
    );
  }
}

ResultPage.propTypes = {
  totalResults: React.PropTypes.number,
  books: React.PropTypes.array,
  onLoadMore: React.PropTypes.func,
};

const mapStateToProps = (state) => {
  console.log(state.books);
  return {
    books: state.books,
  };
};

export default connect(mapStateToProps)(ResultPage);

import React from "react";
import equal from "deep-equal";
import { AllHtmlEntities } from "html-entities";
import Cookies from "js-cookie";
import reqwest from "reqwest";
import { loginAPI } from "../../settings";

const entities = new AllHtmlEntities();

class Textfield extends React.Component {
  static propTypes = {
    alignLabel: React.PropTypes.oneOf(["top", "right", "left", "none"])
      .isRequired,
    name: React.PropTypes.string,
    type: React.PropTypes.string,
    label: React.PropTypes.string,
    className: React.PropTypes.string,
    placeholder: React.PropTypes.string,
    style: React.PropTypes.object,
    tabIndex: React.PropTypes.number,
    min: React.PropTypes.number,
    max: React.PropTypes.number,
    value: React.PropTypes.string,
    defaultValue: React.PropTypes.string,
    required: React.PropTypes.bool,
    hidden: React.PropTypes.bool,
    disabled: React.PropTypes.bool,
    autocompleteBudgetCode: React.PropTypes.string,
    autocomplete: React.PropTypes.array,
    onChange: React.PropTypes.func,
  };

  static defaultProps = {
    placeholder: "",
    type: "text",
    hidden: false,
  };

  constructor() {
    super();
    this.state = {
      codes: [],
    };
  }

  componentDidMount() {
    const sessionid = Cookies.get("sessionID");
    const userlogin = Cookies.get("username");
    if (userlogin && sessionid && this.props.autocompleteBudgetCode) {
      reqwest({
        url: loginAPI,
        data: {
          dataset: "profile",
          userlogin,
          sessionid,
          punchoutid: Cookies.get("punchoutid") || null,
        },
        method: "get",
        crossOrigin: true,
        success: (data) => {
          data = JSON.parse(data);
          data[0].subaccounts &&
            data[0].subaccounts.map((item) => {
              if (this.props.autocompleteBudgetCode == item.account) {
                const input = this._input;
                if (this.autocompleteInstance) {
                  this.autocompleteInstance.destroy();
                }
                this.autocompleteInstance = new autoComplete({
                  selector: input,
                  minChars: 2,
                  source: function (term, suggest) {
                    term = term.toLowerCase();
                    var choices = item.codes.map((code) => {
                      return code.codename;
                    });
                    var matches = [];
                    for (var i = 0; i < choices.length; i++) {
                      if (~choices[i].toLowerCase().indexOf(term)) {
                        matches.push(choices[i]);
                      }
                      suggest(matches);
                    }
                  },
                });
              }
            });
        },
      });
    }

    if (this.props.autocomplete && this.props.autocomplete.length > 0) {
      if (this.autocompleteInstance) {
        this.autocompleteInstance.destroy();
      }
      this.autocompleteInstance = new autoComplete({
        selector: this._input,
        minChars: 1,
        source: (term, suggest) => {
          term = term.toLowerCase();
          var choices = this.props.autocomplete;
          var matches = [];
          for (var i = 0; i < choices.length; i++) {
            if (~choices[i].toLowerCase().indexOf(term)) {
              matches.push(choices[i]);
            }
            suggest(matches);
          }
        },
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(this.props, nextProps) || !equal(this.state, nextState);
  }

  componentDidUpdate() {
    const sessionid = Cookies.get("sessionID");
    const userlogin = Cookies.get("username");
    if (userlogin && sessionid && this.props.autocompleteBudgetCode) {
      reqwest({
        url: loginAPI,
        data: {
          dataset: "profile",
          userlogin,
          sessionid,
          punchoutid: Cookies.get("punchoutid") || null,
        },
        method: "get",
        crossOrigin: true,
        success: (data) => {
          data = JSON.parse(data);
          data[0].subaccounts &&
            data[0].subaccounts.map((item) => {
              if (this.props.autocompleteBudgetCode == item.account) {
                const input = this._input;
                if (this.autocompleteInstance) {
                  this.autocompleteInstance.destroy();
                }
                this.autocompleteInstance = new autoComplete({
                  selector: input,
                  minChars: 2,
                  source: function (term, suggest) {
                    term = term.toLowerCase();
                    var choices = item.codes.map((code) => {
                      return code.codename;
                    });
                    var matches = [];
                    for (var i = 0; i < choices.length; i++) {
                      if (~choices[i].toLowerCase().indexOf(term)) {
                        matches.push(choices[i]);
                      }
                      suggest(matches);
                    }
                  },
                });
              }
            });
        },
      });
    }

    if (this.props.autocomplete && this.props.autocomplete.length > 0) {
      if (this.autocompleteInstance) {
        this.autocompleteInstance.destroy();
      }
      this.autocompleteInstance = new autoComplete({
        selector: this._input,
        minChars: 1,
        source: (term, suggest) => {
          term = term.toLowerCase();
          var choices = this.props.autocomplete;
          var matches = [];
          for (var i = 0; i < choices.length; i++) {
            if (~choices[i].toLowerCase().indexOf(term)) {
              matches.push(choices[i]);
            }
            suggest(matches);
          }
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.autocompleteInstance) {
      this.autocompleteInstance.destroy();
    }
  }

  _onChange = () => {
    if (this.props.onChange) {
      const value = this._input.value;
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <div
        className={`textfield-wrapper ${
          this.props.required ? "required" : ""
        } ${this.props.alignLabel}`}
      >
        {this.props.label && !this.props.hidden ? (
          <label
            className={`textfield-label ${this.props.alignLabel}`}
            htmlFor={this.props.name}
          >
            {[...entities.decode(this.props.label)].map((item, index) => {
              return (
                <span className="ch" key={index}>
                  {item}
                </span>
              );
            })}
            {this.props.required ? "*" : ""}
          </label>
        ) : null}
        {!this.props.hidden && this.props.type != "textarea" ? (
          <input
            ref={(node) => (this._input = node)}
            autoComplete="off"
            className={`textfield ${this.props.alignLabel}`}
            type={this.props.type}
            min={this.props.min || 1}
            max={this.props.max}
            name={this.props.name}
            placeholder={`${this.props.placeholder} ${
              this.props.required ? "*" : ""
            }`}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            style={this.props.style}
            tabIndex={this.props.tabIndex}
            disabled={this.props.disabled || false}
            onChange={this._onChange}
            onBlur={this._onChange}
          />
        ) : null}
        {!this.props.hidden && this.props.type == "textarea" ? (
          <textarea
            ref={(node) => (this._input = node)}
            autoComplete="off"
            className={`textfield ${this.props.alignLabel}`}
            type={this.props.type}
            name={this.props.name}
            placeholder={`${this.props.placeholder} ${
              this.props.required ? "*" : ""
            }`}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            style={this.props.style}
            tabIndex={this.props.tabIndex}
            disabled={this.props.disabled || false}
            onChange={this._onChange}
            onBlur={this._onChange}
          />
        ) : null}
      </div>
    );
  }
}

export default Textfield;

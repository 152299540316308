import React from "react";

const Alert = props => {
  return (
    <div className={`Alert--align-${props.align}`}>
      <div className={`Alert Alert--${props.type}`}>{props.children}</div>
    </div>
  );
};

Alert.propTypes = {
  children: React.PropTypes.string,
  type: React.PropTypes.oneOf(["success", "info", "warning", "danger"]),
  align: React.PropTypes.oneOf(["right", "left", "center", "none"])
};

Alert.defaultProps = {
  align: "none",
  type: "info"
};

export default Alert;

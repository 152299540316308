import React from "react";

const NewTitleAlert = () => {
  return (
    <div style={{ padding: "20px" }}>
      <iframe
        src="https://newsletter.new-books.de/delbanco/?lang=EN"
        width="100%"
        height="800"
        frameBorder="0"
        scrolling="yes"
        marginHeight="0"
        marginWidth="0"
      />
    </div>
  );
};

NewTitleAlert.propTypes = {
  children: React.PropTypes.node
};

NewTitleAlert.defaultProps = {
  children: null
};

export default NewTitleAlert;

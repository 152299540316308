import React from "react";
import reqwest from "reqwest";
import equal from "deep-equal";
import Cookies from "js-cookie";

class Fetch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false
    };
    this._refetchInterval = null;
  }

  componentWillMount() {
    if (this.props.loadOnMount) {
      this._fetch(this.props);
      if (this.props.refetch && this.props.refetch > 0) {
        this._refetchInterval = setInterval(
          () => this._fetch(this.props, true),
          this.props.refetch
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!equal(nextProps.data, this.props.data)) {
      this.setState({
        done: false
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.refetch !== nextProps.refetch) {
      if (this._refetchInterval !== null) {
        clearInterval(this._refetchInterval);
      }
      if (nextProps.refetch && nextProps.refetch > 0) {
        this._refetchInterval = setInterval(
          () => this._fetch(this.props, true),
          nextProps.refetch
        );
      }
    }
    const { timestamp: _nextTimestamp, ...nextData } = nextProps.data;
    const { timestamp: _thisTimestamp, ...thisData } = this.props.data;
    if (equal(nextData, thisData) && this.props.url === nextProps.url) {
      if (this.state.done == false || nextState.done == false) {
        this.setState({
          done: true
        });
      }
      return;
    }
    this._fetch(nextProps);
  }

  componentWillUnmount() {
    if (this._refetchInterval !== null) {
      clearInterval(this._refetchInterval);
    }
  }

  _fetch = (props, refetch = false) => {
    const sessionData = this.props.addSessionData
      ? {
          userlogin: Cookies.get("username"),
          sessionid: Cookies.get("sessionID"),
          subaccount: Cookies.get("subaccount"),
          punchoutid: Cookies.get("punchoutid") || null
        }
      : {};
    reqwest({
      url: props.url,
      type: "json",
      data: {
        ...sessionData,
        ...props.data,
        ...(refetch && props.refetchData ? props.refetchData : {})
      },
      method: this.props.method,
      crossOrigin: true,
      success: data => {
        this._handleSuccess(data, refetch);
      }
    });
  };

  _handleSuccess = (data, refetched) => {
    if (typeof data !== "object") {
      data = JSON.parse(data);
    }
    if (data && data[0] && data[0].errormessage == "ERROR:session expired") {
      window.location.href = "/logout?session=false";
    }
    this.setState({
      done: true,
      refetched,
      data
    });
  };

  render() {
    if (this.state.done) {
      return this.props.children(this.state);
    } else {
      return this.props.children(this.state);
    }
  }
}

Fetch.propTypes = {
  children: React.PropTypes.func.isRequired,
  url: React.PropTypes.string.isRequired,
  method: React.PropTypes.string,
  data: React.PropTypes.object,
  loadOnMount: React.PropTypes.bool,
  refetch: React.PropTypes.number,
  refetchData: React.PropTypes.object,
  addSessionData: React.PropTypes.bool
};

Fetch.defaultProps = {
  addSessionData: true,
  loadOnMount: true,
  refetchData: {},
  method: "GET"
};

export default Fetch;

import React from "react";
import currencyFormatter from "currency-formatter";
import ShoppingCartItem from "./ShoppingCartItem";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import {
  getInitialCart,
  updateQuantity,
  removeFromCart,
  orderCart,
  setSelectedCart,
} from "../../actions/actions";
import RenderHTML from "../RenderHTML";
import Selectbox from "../inputs/Selectbox";
import Button from "../inputs/Button";
import queryString from "query-string";

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOption: "unselect",
      hidden: window.innerWidth <= 1024 ? true : false,
    };
  }
  componentDidMount() {
    this.props.getInitialCart();
  }
  _toggleShoppingCart = () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  };

  _updateQuantity = (index, quantity) => {
    this.props.updateQuantity(Cookies.get("subaccount"), index, quantity);
  };

  _removeItem = (index) => {
    this.props.removeFromCart(index);
  };

  _setSelectedCart = (cart) => {
    this.props.setSelectedCart(cart);
  };

  render() {
    const urlParams = queryString.parse(window.location.search.toLowerCase());
    let isPunchout = window.POST["hook_url"] || urlParams.hook_url;
    let totalPrice = 0;
    let currency = "";
    this.props.items.forEach((item) => {
      totalPrice = totalPrice + item.vkpr * (item.copy || 1);
      currency = item.kdfw;
    });
    return (
      <div
        className={`shopping-cart-wrapper ${this.state.hidden ? "in" : "out"}`}
      >
        <button
          className="shopping-cart-toggler"
          onClick={this._toggleShoppingCart}
        >
          <img
            src="/images/icons/cart.png"
            aria-hidden="true"
            alt="Einkaufswagen"
          />
          {this.props.items && (
            <span className="shopping-cart-count">
              {this.props.items.reduce((sum, item) => {
                return sum + parseInt(item.copy, 10);
              }, 0)}
            </span>
          )}
        </button>
        <div className="shopping-cart-select">
          <span className="shopping-cart-select-text">
            Selected Shopping Cart:
          </span>
          <Selectbox
            alignLabel="none"
            label=""
            value={this.props.selectedCart}
            updateAction={this._setSelectedCart}
            options={this.props.availableCarts.map((cart) => ({
              name: cart,
              value: cart,
            }))}
          />
        </div>
        <div className="shopping-cart-total">
          Total:{" "}
          {currencyFormatter.format(totalPrice, {
            symbol: currency,
            decimal: ",",
            thousand: ".",
            precision: 2,
            format: "%v %s", // %s is the symbol and %v is the value
          })}
        </div>
        {this.props.donotsendorder == "1" ? (
          <button
            className="shopping-cart-submit is-disabled"
            type="button"
            onClick={() => null}
          >
            {this.props.donotsendorderHtml ? (
              <RenderHTML html={this.props.donotsendorderHtml} />
            ) : (
              "To place the order, please contact the acquisition team"
            )}
          </button>
        ) : (
          Cookies.get("orderpermit") != "0" && (
            <button
              className={`shopping-cart-submit ${
                this.props.items &&
                this.props.items.length > 0 &&
                !this.props.items.every((item) => item.placeorder === "0")
                  ? ""
                  : "is-disabled"
              }`}
              type="button"
              onClick={() => {
                if (this.props.items && this.props.items.length > 0) {
                  return this.props.orderCart();
                } else {
                  return null;
                }
              }}
            >
              Order now
            </button>
          )
        )}
        <div className="shopping-cart-flag">
          <img
            src="/images/icons/cart.png"
            aria-hidden="true"
            alt="Einkaufswagen"
          />
        </div>
        {!isPunchout && (
          <div className="shopping-cart-buttons">
            <Button
              onClick={() => {
                if (this.state.selectOption === "unselect") {
                  document.dispatchEvent(new Event("scart:unselect-all"));
                  this.setState({
                    selectOption: "select",
                  });
                } else {
                  document.dispatchEvent(new Event("scart:select-all"));
                  this.setState({
                    selectOption: "unselect",
                  });
                }
              }}
            >
              Select / Unselect all
            </Button>
          </div>
        )}
        <div className="shopping-cart-items">
          {this.props.items &&
            this.props.items.map((item) => {
              return (
                <ShoppingCartItem
                  key={`${item.isbn}_${item.lfd}`}
                  placeorder={item.placeorder}
                  index={item.lfd}
                  item={item}
                  isbn={item.isbn}
                  title={item.titl}
                  konto={item.knut}
                  budgetCode={item.code}
                  ebrary={item.elic}
                  reference={item.kref}
                  offerTextDescription={item.offerTextDescription}
                  price={item.vkpr && Number(item.vkpr)}
                  currency={item.kdfw}
                  quantity={(item.copy && parseInt(item.copy)) || 1}
                  onRemove={this._removeItem}
                  onUpdateQuantity={this._updateQuantity}
                  fund={item.fund}
                  library={item.library}
                  location={item.location}
                  reportingCode={item.reportingCode}
                  reportingCode2={item.reportingCode2}
                  interestedUser={item.interestedUser}
                  receivingNote={item.receivingNote}
                />
              );
            })}
          {this.props.loading && (
            <div className="shopping-cart-loading">Loading...</div>
          )}
        </div>
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  loading: React.PropTypes.bool,
  items: React.PropTypes.arrayOf(
    React.PropTypes.shape({
      isbn: React.PropTypes.string,
      lfd: React.PropTypes.string,
      title: React.PropTypes.string,
      author: React.PropTypes.string,
      price: React.PropTypes.number,
      quantity: React.PropTypes.number,
    })
  ),
  availableCarts: React.PropTypes.arrayOf(React.PropTypes.string),
  selectedCart: React.PropTypes.string,
  donotsendorder: React.PropTypes.string,
  donotsendorderHtml: React.PropTypes.string,
  rtaApiKey: React.PropTypes.string,
  orderCart: React.PropTypes.func,
  removeFromCart: React.PropTypes.func,
  updateQuantity: React.PropTypes.func,
  setSelectedCart: React.PropTypes.func,
  getInitialCart: React.PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loading: state.cart.loading > 0,
    items: state.cart.cart,
    selectedCart: state.cart.selectedCart,
    availableCarts: state.cart.availableCarts,
    donotsendorder: state.cart.clientInfo.donotsendorder,
    donotsendorderHtml: state.cart.clientInfo.donotsendorderhtml,
    rtaApiKey: state.cart.clientInfo.rta
      ? state.cart.clientInfo.rta.apikey
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInitialCart: () => {
      dispatch(getInitialCart());
    },
    removeFromCart: (item) => {
      dispatch(removeFromCart(item));
    },
    updateQuantity: (subaccount, item, quantity) => {
      dispatch(updateQuantity(subaccount, item, quantity));
    },
    orderCart: () => {
      dispatch(orderCart());
    },
    setSelectedCart: (cart) => {
      dispatch(setSelectedCart(cart));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);

import React from "react";
import Fetch from "../components/Fetch";
import Cookies from "js-cookie";
import { loginAPI } from "../settings";

export function removeCookies() {
  Cookies.remove("punchoutid");
  Cookies.remove("cartURL");
  Cookies.remove("username");
  Cookies.remove("sessionID");
  Cookies.remove("password");
  Cookies.remove("allsubaccounts");
  Cookies.remove("subaccount");
  Cookies.remove("mainaccounts");
  Cookies.remove("firstname");
  Cookies.remove("lastname");
  Cookies.remove("orderpermit");
}

const Logout = () => {
  return (
    <Fetch
      url={loginAPI}
      data={{
        dataset: "user",
        userlogin: Cookies.get("username"),
        logout: "1",
      }}
    >
      {() => {
        removeCookies();
        localStorage.removeItem("selectedCart");
        if (window.location.href.indexOf("session=false") > -1) {
          window.location.href = "/login?session=false";
          return null;
        } else {
          window.location.href = "/login";
          return null;
        }
      }}
    </Fetch>
  );
};

export default Logout;

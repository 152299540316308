import React from "react";
import Cookies from "js-cookie";
import reqwest from "reqwest";
import swal from "sweetalert";

import { removeCookies } from "./Logout";
import Sidebar from "../components/layout/Sidebar";
import Greeter from "../components/Greeter";
import Navigation from "../components/navigation/Navigation";
import ChangeSubAccount from "../modals/changeSubAccount";

import queryString from "query-string";
import { Base64 } from "js-base64";
import generateUuid from "uuid/v1";
import { loginAPI } from "../settings";

class SecuredSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      showOnlyBookCatalogue: Cookies.get("cartURL") ? true : false,
    };
  }
  componentWillMount() {
    const urlParams = queryString.parse(window.location.search);

    let username = window.POST["benutzer"];
    let password = window.POST["password"];
    let cartURL = window.POST["hook_url"] || window.POST["HOOK_URL"];

    Object.keys(urlParams).map((item) => {
      switch (item.toLowerCase()) {
        case "hook_url":
          cartURL = urlParams[item];
          break;
        case "benutzer":
          username = urlParams[item];
          break;
        case "password":
          password = urlParams[item];
          break;
        default:
          break;
      }
    });

    if (cartURL) removeCookies();

    const sessionid = Cookies.get("sessionID");
    const userlogin = Cookies.get("username");

    if (userlogin && sessionid) {
      reqwest({
        url: loginAPI,
        data: {
          dataset: "profile",
          userlogin,
          sessionid,
          punchoutid: Cookies.get("punchoutid") || null,
        },
        method: "get",
        crossOrigin: true,
        success: (data) => {
          data = JSON.parse(data);
          if (!data[0].errormessage) {
            this.setState({
              loggedIn: true,
            });
          } else {
            if (username && password) {
              reqwest({
                url: loginAPI,
                data: {
                  dataset: "user",
                  userlogin: username,
                  password,
                  punchoutid: Cookies.get("punchoutid") || null,
                },
                method: "get",
                crossOrigin: true,
                success: (data) => {
                  data = JSON.parse(data);
                  if (cartURL) {
                    Cookies.set("cartURL", cartURL);
                  }
                  Cookies.set("username", username);
                  Cookies.set("sessionID", data[0].sessionid);
                  Cookies.set("password", Base64.encode(password));
                  const prevUrl = localStorage.getItem("prevURL");
                  if (prevUrl) {
                    localStorage.removeItem("prevURL");
                    window.location.href = prevUrl;
                  } else {
                    window.location.href = "/";
                  }
                },
              });
            } else {
              localStorage.setItem(
                "prevURL",
                location.href.replace(location.origin, "")
              );
              window.location.href = "/logout";
            }
          }
          const allsubaccounts =
            data[0].subaccounts &&
            data[0].subaccounts
              .map((item) => {
                return item.account;
              })
              .join(",");
          if (Cookies.get("allsubaccounts") != allsubaccounts) {
            Cookies.set("allsubaccounts", allsubaccounts || "");
          }
          if (!Cookies.get("subaccount") && data[0].defaultsubaccount) {
            Cookies.set("subaccount", data[0].defaultsubaccount || "");
          } else if (!Cookies.get("subaccount")) {
            Cookies.set(
              "subaccount",
              (data[0].subaccounts &&
                data[0].subaccounts
                  .map((item) => {
                    return item.account;
                  })
                  .filter((item) => item)[0]) ||
                ""
            );
            swal({
              title: "Your Subaccount",
              text: "You currently have no default Subaccount selected in your profile. We have automatically selected the first one in your list, if you wish to change this for future sessions please click on the “Profile” tab in the left menu and change your preferences.",
              showConfirmButton: true,
              type: "info",
              allowOutsideClick: false,
            });
          }
          const budgetCodes =
            data[0].subaccounts &&
            data[0].subaccounts
              .map((item) => {
                if (item.account == Cookies.get("subaccount")) {
                  return (
                    item.codes &&
                    item.codes
                      .map((code) => {
                        if (code.codename)
                          return {
                            name: code.codename,
                            value: code.codename,
                          };
                      })
                      .filter((item) => item)
                  );
                }
              })
              .filter((item) => item)[0];
          if (Cookies.get("budgetCodes") != budgetCodes) {
            Cookies.set("budgetCodes", budgetCodes || "");
          }
          if (Cookies.get("mainaccounts") != data[0].mainaccounts) {
            Cookies.set("mainaccounts", data[0].mainaccounts || "");
          }
          if (Cookies.get("firstname") != data[0].firstname) {
            Cookies.set("firstname", data[0].firstname || "");
          }
          if (Cookies.get("lastname") != data[0].lastname) {
            Cookies.set("lastname", data[0].lastname || "");
          }
          if (Cookies.get("orderpermit") != data[0].orderpermit) {
            Cookies.set("orderpermit", data[0].orderpermit || "");
          }
          if (data[0].preferences) {
            if (
              Cookies.get("donotshowfastoption") !=
              data[0].preferences.donotshowfastoption
            ) {
              Cookies.set(
                "donotshowfastoption",
                data[0].preferences.donotshowfastoption || ""
              );
            }
            if (
              Cookies.get("budgetcodeMandatory") !=
              data[0].preferences.budgetcodeMandatory
            ) {
              Cookies.set(
                "budgetcodeMandatory",
                data[0].preferences.budgetcodeMandatory || ""
              );
            }
            if (
              Cookies.get("referenceMandatory") !=
              data[0].preferences.referenceMandatory
            ) {
              Cookies.set(
                "referenceMandatory",
                data[0].preferences.referenceMandatory || ""
              );
            }
            if (
              Cookies.get("referenceText") != data[0].preferences.referenceText
            ) {
              Cookies.set(
                "referenceText",
                data[0].preferences.referenceText || ""
              );
            }
          }
        },
      });
    } else {
      if (username && password) {
        // The user provided a username and password in the url.
        // Let's jus assume he need a punchoutid and generate it....
        const uuid = Base64.encode(generateUuid()).substring(0, 10);
        Cookies.set("punchoutid", uuid);

        reqwest({
          url: loginAPI,
          data: {
            dataset: "user",
            userlogin: username,
            password,
            punchoutid: Cookies.get("punchoutid") || null,
          },
          method: "get",
          crossOrigin: true,
          success: (data) => {
            data = JSON.parse(data);
            if (cartURL) {
              Cookies.set("cartURL", cartURL);
              this.setState({
                showOnlyBookCatalogue: true,
              });
            }
            Cookies.set("username", username);
            Cookies.set("sessionID", data[0].sessionid);
            Cookies.set("password", Base64.encode(password));
            const prevUrl = localStorage.getItem("prevURL");
            if (prevUrl) {
              localStorage.removeItem("prevURL");
              window.location.href = prevUrl;
            } else {
              window.location.href = "/";
            }
          },
        });
      } else {
        localStorage.setItem(
          "prevURL",
          location.href.replace(location.origin, "")
        );
        window.location.href = "/logout";
      }
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        subaccount: Cookies.get("subaccount"),
      });
    }, 1000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.loggedIn != this.state.loggedIn ||
      nextState.subaccount != this.state.subaccount ||
      nextProps.children != this.props.children
    );
  }

  render() {
    if (!this.state.loggedIn) return null;

    return (
      <div className="main-content">
        <Sidebar
          sidebar={
            <div className="sidebar">
              <Greeter />
              <Navigation
                paths={{
                  nodes: [
                    {
                      name: "Content",
                      path: "",
                      nodes: this.state.showOnlyBookCatalogue
                        ? [
                            {
                              name: "Catalogue: Print / Ebooks",
                              path: "/catalogue",
                            },
                          ]
                        : [
                            {
                              name: "Catalogue: Print / Ebooks",
                              path: "/catalogue",
                            },
                            {
                              name: "Order Tracking: Books / Ebooks",
                              path: "/tracking-books",
                            },
                            {
                              name: "Order Tracking: Journals / Serials",
                              path: "/tracking-serials",
                            },
                            {
                              name: "Ready to ship",
                              path: "/ready-to-ship",
                            },
                            {
                              name: "Parcels",
                              path: "/parcels",
                            },
                            {
                              name: "Invoices",
                              path: "/invoices",
                            },
                            {
                              name: "New Title Alert",
                              path: "/alerts",
                            },
                            {
                              name: "Profile",
                              path: "/profile",
                            },
                            {
                              name: `
                                                Change Sub-Account <br />
                                            Current Sub-Account: ${
                                              Cookies.get("subaccount")
                                                ? Cookies.get("subaccount")
                                                : Cookies.get("mainaccounts") &&
                                                  Cookies.get(
                                                    "mainaccounts"
                                                  ).indexOf("ALL") != -1
                                                ? "ALL"
                                                : ""
                                            }
                                            `,
                              render: <ChangeSubAccount />,
                            },
                          ],
                    },
                  ],
                }}
              />
            </div>
          }
          content={<div className="flex-container">{this.props.children}</div>}
        />
      </div>
    );
  }
}

SecuredSection.propTypes = {
  children: React.PropTypes.node,
};

SecuredSection.defaultProps = {
  children: null,
};

export default SecuredSection;

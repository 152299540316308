import React from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { addToCart } from "../../actions/actions";

import Button from "../../components/inputs/Button";
import Textfield from "../../components/inputs/Textfield";
import objectAssign from "object-assign";
import Modal from "../../components/Modal";
import Checkbox from "../../components/inputs/Checkbox";

import moment from "moment";
import swal from "sweetalert";
import Selectbox from "../../components/inputs/Selectbox";
import SelectboxMultiple from "../../components/inputs/SelectboxMultiple";

class Searchform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedSearch: false,
      orderManually: false,
      language: "all",
      readership: [],
    };
  }

  _toggleAdvancedSearch = () => {
    this.setState({
      advancedSearch: !this.state.advancedSearch,
    });
  };

  _orderManually = () => {
    this.setState({
      orderManually: !this.state.orderManually,
    });
  };

  _submitAddToCart = () => {
    if (!this._orderform.reportValidity()) {
      return;
    }

    const isbn10 = this._isbn._input.value || "";
    const title = this._title._input.value || "";
    const authors = this._authors._input.value || "";
    const publisher = this._publisher._input.value || "";
    const publishedYear = this._publishedDate._input.value || "";
    const coverType = this._coverType._input.value || "";
    const kref = this._kref._input.value || "";
    const code = this._code._input.value || "";
    const copy = this._copy._input.value || "";
    const urgt = this._urgt._input.checked ? "1" : "0";
    const details = this._details._input.value || "";

    if (Cookies.get("referenceMandatory") == "1" && kref == "") {
      swal({
        title: "Please fill out mandatory fields",
        text: `Please fill out all fields marked with *`,
        showConfirmButton: true,
        type: "error",
        allowOutsideClick: false,
      });
      return;
    }

    if (Cookies.get("budgetcodeMandatory") == "1" && code == "") {
      swal({
        title: "Please fill out mandatory fields",
        text: `Please fill out all fields marked with *`,
        showConfirmButton: true,
        type: "error",
        allowOutsideClick: false,
      });
      return;
    }

    this.setState({
      orderManually: false,
    });

    this.props.addToCart(Cookies.get("subaccount"), {
      isbn: isbn10,
      titl: title,
      auth: authors,
      publ: publisher,
      pdat: publishedYear,
      kref,
      code,
      cove: coverType,
      copy,
      kdat: moment().format("DD.MM.YYYY"),
      urgt,
      detl: details,
    });
  };

  _clearForm = () => {
    this._isbn ? (this._isbn._input.value = "") : null;
    this._title ? (this._title._input.value = "") : null;
    this._authors ? (this._authors._input.value = "") : null;
    this._publisher ? (this._publisher._input.value = "") : null;
    this._publishedDate ? (this._publishedDate._input.value = "") : null;
    this._coverType ? (this._coverType._input.value = "") : null;
    this._kref ? (this._kref._input.value = "") : null;
    this._code ? (this._code._input.value = "") : null;
    this._copy ? (this._copy._input.value = "") : null;
    this._urgt ? (this._urgt._input.checked = false) : null;
    this._details ? (this._details._input.value = "") : null;
    this._keyword ? (this._keyword._input.value = "") : null;
    this._title ? (this._title._input.value = "") : null;
    this._isbn ? (this._isbn._input.value = "") : null;
    this._authors ? (this._authors._input.value = "") : null;
    this._year ? (this._year._input.value = "") : null;
    this._publisher ? (this._publisher._input.value = "") : null;
    this._ddc ? (this._ddc._input.value = "") : null;
    this._udc ? (this._udc._input.value = "") : null;
    this._bic ? (this._bic._input.value = "") : null;
    this._searchword ? (this._searchword._input.value = "") : null;
    this.setState({
      language: "all",
      readership: [],
    });
  };

  _handleSubmit = (e, type) => {
    if (e) e.preventDefault();

    if (!this._searchform.reportValidity()) {
      return;
    }

    if (typeof this.props.onSubmit !== "function") {
      console.error(
        "You have to provide an onSubmit function. None was found."
      );
      return;
    }

    let binding = "";
    if (type == "ebooks") {
      binding = "37";
    } else if (type == "print") {
      binding = "!37";
    }

    const data = {
      binding,
      keyword: this._keyword._input.value,
      title: this._title._input.value,
      isbn: this._isbn._input.value,
      authors: this._authors._input.value,
      year: "",
      publisher: "",
      ddc: "",
      udc: "",
      bic: "",
      searchword: "",
      language: this.state.language,
      readership: this.state.readership.join(","),
    };

    let advancedData = {};
    if (this.state.advancedSearch) {
      advancedData = {
        year: this._year._input.value || "",
        publisher: this._publisher._input.value || "",
        ddc: this._ddc._input.value || "",
        udc: this._udc._input.value || "",
        bic: this._bic._input.value || "",
        searchword: this._searchword._input.value || "",
        standardCode: this._stand_code._input
          ? this._stand_code._input.value
          : "",
        standardRef: this._stand_ref._input ? this._stand_ref._input.value : "",
        initialsPrefixRef: this._prefix._input.checked ? "1" : "0",
      };
    }

    this.props.onSubmit(objectAssign({}, data, advancedData));
    return false;
  };

  render() {
    return (
      <div>
        <form
          className="search-form"
          ref={(node) => (this._searchform = node)}
          onSubmit={this._handleSubmit}
        >
          <div className="row">
            <Textfield
              ref={(node) => (this._keyword = node)}
              placeholder="Keyword"
            />
            <Textfield
              ref={(node) => (this._title = node)}
              placeholder="Title"
            />
            <Textfield ref={(node) => (this._isbn = node)} placeholder="ISBN" />
            <Textfield
              ref={(node) => (this._authors = node)}
              placeholder="Author"
            />
          </div>
          {this.state.advancedSearch ? (
            <div className="row">
              <Textfield
                ref={(node) => (this._year = node)}
                placeholder="Published Year"
              />
              <Textfield
                ref={(node) => (this._publisher = node)}
                placeholder="Publisher"
              />
              <Textfield ref={(node) => (this._ddc = node)} placeholder="DDC" />
              <Textfield ref={(node) => (this._udc = node)} placeholder="UDC" />
            </div>
          ) : null}
          {this.state.advancedSearch ? (
            <div className="row">
              <Textfield
                ref={(node) => (this._bic = node)}
                placeholder="BIC2"
              />
              <Textfield
                ref={(node) => (this._searchword = node)}
                placeholder="LOC Subject Headings"
              />
              <Selectbox
                alignLabel="none"
                label=""
                updateAction={(value) => {
                  this.setState({
                    language: value,
                  });
                }}
                placeholder="Language"
                value={this.state.language}
                options={[
                  {
                    value: "all",
                    name: "All Languages",
                  },
                  {
                    value: "english",
                    name: "English",
                  },
                  {
                    value: "french",
                    name: "French",
                  },
                  {
                    value: "german",
                    name: "German",
                  },
                  {
                    value: "italian",
                    name: "Italian",
                  },
                  {
                    value: "norwegian",
                    name: "Norwegian",
                  },
                  {
                    value: "spanish",
                    name: "Spanish",
                  },
                  {
                    value: "swedish",
                    name: "Swedish",
                  },
                  {
                    value: "other",
                    name: "Other",
                  },
                ]}
              />
              <SelectboxMultiple
                alignLabel="none"
                label=""
                updateAction={(value) => {
                  this.setState({
                    readership: value,
                  });
                }}
                value={this.state.readership}
                placeholder="Readership Level"
                options={[
                  {
                    value: "general",
                    name: "General",
                  },
                  {
                    value: "Tertiary and Higher Education",
                    name: "Tertiary and Higher Education",
                  },
                  {
                    value: "Technical / vocational",
                    name: "Technical / vocational",
                  },
                  {
                    value: "Undergraduate",
                    name: "Undergraduate",
                  },
                  {
                    value: "Postgraduate",
                    name: "Postgraduate",
                  },
                  {
                    value: "Professional, Research and Scholarly",
                    name: "Professional, Research and Scholarly",
                  },
                  {
                    value: "Children / Juvenile",
                    name: "Children / Juvenile",
                  },
                  {
                    value: "Teenage / Young Adult",
                    name: "Teenage / Young Adult",
                  },
                  {
                    value: "Educational: Primary / Secondary",
                    name: "Educational: Primary / Secondary",
                  },
                  {
                    value: "Student Text",
                    name: "Student Text",
                  },
                  {
                    value: "Teacher Text",
                    name: "Teacher Text",
                  },
                  {
                    value: "Revision / study guides",
                    name: "Revision / study guides",
                  },
                  {
                    value: "Exams / tests / exercises",
                    name: "Exams / tests / exercises",
                  },
                  {
                    value: "ELT general",
                    name: "ELT general",
                  },
                  {
                    value: "ELT beginner",
                    name: "ELT beginner",
                  },
                  {
                    value: "ELT advanced",
                    name: "ELT advanced",
                  },
                  {
                    value: "ELT intermediate",
                    name: "ELT intermediate",
                  },
                  {
                    value: "English as a second language",
                    name: "English as a second language",
                  },
                ]}
              />
            </div>
          ) : null}
          {this.state.advancedSearch ? <hr /> : null}
          {this.state.advancedSearch ? (
            <div className="row">
              <Textfield
                ref={(node) => (this._stand_code = node)}
                placeholder="Standard Budget Code"
                autocompleteBudgetCode={Cookies.get("subaccount")}
              />
              <Textfield
                ref={(node) => (this._stand_ref = node)}
                placeholder="Standard Reference"
              />
              <Checkbox
                name="initialsAsPrefix"
                ref={(node) => (this._prefix = node)}
                onClick={(value) => value}
                value="1"
                label="Use Initials as Prefix Reference"
              />
            </div>
          ) : null}
          <div className="button-wrapper">
            <Button href="https://booksearch.delbanco.de/life/help/">
              Help
            </Button>
            <Button type="danger" onClick={this._clearForm}>
              Clear
            </Button>
            <Button
              onClick={this._orderManually}
              disabled={Cookies.get("orderpermit") == "0"}
            >
              Order manually
            </Button>
            <Button onClick={this._toggleAdvancedSearch}>
              {!this.state.advancedSearch
                ? "Advanced Search"
                : "Close advanced Search"}
            </Button>
            <Button
              type="default-green"
              onClick={(e) => this._handleSubmit(e, "ebooks")}
            >
              Search E-Books only
            </Button>
            <Button
              type="default-green"
              onClick={(e) => this._handleSubmit(e, "print")}
            >
              Search Print only
            </Button>
            <Button type="primary" onClick={(e) => this._handleSubmit(e)}>
              Search
            </Button>
          </div>
        </form>
        <Modal open={this.state.orderManually} onClose={this._orderManually}>
          <form ref={(node) => (this._orderform = node)}>
            <Textfield
              type="number"
              max={9999999999999}
              ref={(node) => (this._isbn = node)}
              placeholder="ISBN"
            />
            <Textfield
              ref={(node) => (this._title = node)}
              placeholder="Title"
            />
            <Textfield
              ref={(node) => (this._authors = node)}
              placeholder="Author"
            />
            <Textfield
              ref={(node) => (this._publisher = node)}
              placeholder="Publisher"
            />
            <Textfield
              ref={(node) => (this._publishedDate = node)}
              placeholder="Published Date"
            />
            <Textfield
              ref={(node) => (this._coverType = node)}
              placeholder="Cover Type"
            />
            <Textfield
              ref={(node) => (this._kref = node)}
              placeholder={Cookies.get("referenceText")}
              required={Cookies.get("referenceMandatory") == "1"}
            />
            <Textfield
              ref={(node) => (this._code = node)}
              placeholder="Your Budget Code"
              required={Cookies.get("budgetcodeMandatory") == "1"}
              autocompleteBudgetCode={Cookies.get("subaccount")}
            />
            <Textfield
              type="number"
              ref={(node) => (this._copy = node)}
              placeholder="Copies"
            />
            <Textfield
              ref={(node) => (this._kdat = node)}
              disabled={true}
              value={moment().format("DD.MM.YYYY")}
              placeholder="Order Date"
            />
            <Checkbox
              ref={(node) => (this._urgt = node)}
              onClick={(value) => value}
              value="1"
              label="Urgent"
              name="urgent"
            />
            <Textfield
              type="textarea"
              ref={(node) => (this._details = node)}
              placeholder="Details - note to Delbanco"
            />
            <div className="button-wrapper">
              <Button type="danger" onClick={this._orderManually}>
                Cancel
              </Button>
              <Button type="default" onClick={this._submitAddToCart}>
                Add to Cart
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

Searchform.propTypes = {
  onSubmit: React.PropTypes.func,
  addToCart: React.PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (subaccount, item) => {
      dispatch(addToCart(subaccount, item));
    },
  };
};

export default connect(null, mapDispatchToProps)(Searchform);

import React from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { addToCart } from "../../actions/actions";
import { AllHtmlEntities } from "html-entities";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "../../components/Modal";
import Button from "../../components/inputs/Button";
import Checkbox from "../../components/inputs/Checkbox";
import Textfield from "../../components/inputs/Textfield";
import Selectbox from "../../components/inputs/Selectbox";
import Loader from "../../components/Loader";
import AlternateEdition from "./ResultItemAlternation";
import moment from "moment";
import reqwest from "reqwest";
import queryString from "query-string";
import swal from "sweetalert";
import RenderHTML from "../../components/RenderHTML";
import Radio from "../../components/inputs/Radio";
import Fetch from "../../components/Fetch";
import { bakScarterAPI, searchAPINo2 } from "../../settings";

const entities = new AllHtmlEntities();

class SearchResultItem extends React.Component {
  constructor(props) {
    super(props);

    let spineLabel = false;
    let showShelfReady = false;
    const clientInfo = props.data.clientInfo;
    if (clientInfo) {
      const volumeInfo = props.data.volumeInfo || {};
      if (volumeInfo.bindingText) {
        const binding = volumeInfo.bindingText;
        spineLabel =
          binding != "E-Book" && clientInfo.showspine == "1" ? true : false;
      }
      showShelfReady = clientInfo.showshelfready == "1" ? true : false;
    }

    this.state = {
      loadingSpinner: false,
      openModal: false,
      confirmModal: false,
      confirmModalAction: () => null,

      requestRTA: false,
      rtaLibraries: [],
      rtaFunds: [],
      rtaReportingCodes: [],
      rtaReportingCodes2: [],
      rtaInterestedUser: {},
      rtaReceivingNote: {},

      locationDescription: "",

      spineLabel: spineLabel,
      shelfready: showShelfReady,

      fund: "",
      library: "",
      location: "",
      reportingCode: "",
      reportingCode2: "",
      interestedUser: "",
      receivingNote: "",
    };
    Tabs.setUseDefaultStyles(false);

    this._queryString = queryString.parse(window.location.href);
  }

  componentWillMount() {
    const alternateEditions = this.props.data.alternateEditions || null;
    if (!alternateEditions) {
      this._fetchSellingInfo();
    }
  }

  _toggleConfirm = (action = () => null) => {
    const clientInfo = this.props.data.clientInfo;
    const orderHistory = clientInfo && clientInfo.orderHistory;

    if (orderHistory) {
      this.setState({
        confirmModal: true,
        confirmModalAction: action,
      });
    } else {
      action();
    }
  };

  _addToCart = () => {
    if (this.props.rtaApiKey) {
      const librariesRequest = reqwest({
        url: bakScarterAPI,
        data: {
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "libraries",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      const reportingCodesRequest = reqwest({
        url: bakScarterAPI,
        data: {
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "reportingCodes",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      const reportingCodes2Request = reqwest({
        url: bakScarterAPI,
        data: {
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "reportingCodes2",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      const interestedUserRequest = reqwest({
        url: bakScarterAPI,
        data: {
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "interestedUser",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      const receivingNoteRequest = reqwest({
        url: bakScarterAPI,
        data: {
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "receivingNote",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      const fundRequest = reqwest({
        url: bakScarterAPI,
        data: {
          limit: 100,
          view: "full",
          apikey: this.props.rtaApiKey,
          rtaAPI: 1,
          action: "funds",
        },
        type: "json",
        method: "GET",
        crossOrigin: true,
      });
      Promise.all([
        librariesRequest,
        fundRequest,
        reportingCodesRequest,
        reportingCodes2Request,
        interestedUserRequest,
        receivingNoteRequest,
      ]).then(
        ([
          libraries,
          funds,
          reportingCodes,
          reportingCodes2,
          interestedUser,
          receivingNote,
        ]) => {
          this.setState({
            requestRTA: true,
            rtaLibraries: libraries.library || [],
            rtaFunds: funds.fund || [],
            rtaReportingCodes: reportingCodes || [],
            rtaReportingCodes2: reportingCodes2 || [],
            rtaInterestedUser: interestedUser || {},
            rtaReceivingNote: receivingNote || {},

            confirmModal: false,
            confirmModalAction: () => null,
            modalOpen: true,
          });
        }
      );
    } else {
      this.setState({
        confirmModal: false,
        confirmModalAction: () => null,
        modalOpen: true,
      });
    }
  };

  _submitAddToCart = async () => {
    const clientInfo = this.props.data.clientInfo;
    const orderHistory = clientInfo && clientInfo.orderHistory;

    const volumeInfo = this.props.data.volumeInfo || {};
    const title = volumeInfo.title || "";
    const authors = volumeInfo.authors || "";
    const isbn = `${volumeInfo.isbn13}` || "";
    const publisher = volumeInfo.publisher || "";
    const publishedYear = volumeInfo.publishedYear || "";
    const weight = volumeInfo.weight || "";
    const kref = this._kref._input.value || "";
    let code = "";

    if (!this.state.requestRTA) {
      code = this._code._input.value || "";
    }
    const copy = this._copy._input.value || "1";
    const urgt = this._urgt._input.checked ? "1" : "0";
    const details = this._details._input.value || "";
    const ebrary = this.state.ebrary || "";
    let rtaOrderData = {};

    if (this.state.requestRTA) {
      rtaOrderData = {
        fund: this.state.fundCode,
        fundDescription: this.state.rtaFunds.find(
          (fc) => fc.code == this.state.fundCode
        ).name,
        library: this.state.library,
        libraryDescription: this.state.rtaLibraries.find(
          (l) => l.code == this.state.library
        ).name,
        location: this.state.location,
        locationDescription: this.state.locationDescription,
        reportingCode: this.state.reportingCode || "",
        reportingCode2: this.state.reportingCode2 || "",
        interestedUser: this.state.interestedUser || "",
        receivingNote: this.state.receivingNote || "",
      };
    }

    const notRtaOrderData = {
      code: code.toUpperCase(),
    };

    if (this.state.requestRTA) {
      const libaries = await reqwest({
        type: "json",
        method: "GET",
        crossOrigin: true,
        url: bakScarterAPI,
        data: {
          rtaAPI: 1,
          action: "locations",
          library: rtaOrderData.library,
          apikey: this.props.rtaApiKey,
        },
      });

      if (!libaries.total_record_count) {
        rtaOrderData.location = rtaOrderData.library;
      }
    }

    let showMarc = false;
    let showSpine = false;
    let showShelfReady = false;
    let showShelfReadyDetails = false;
    if (clientInfo) {
      showMarc = clientInfo.showmarc == "1" ? true : false;
      showSpine = clientInfo.showspine == "1" ? true : false;
      showShelfReady = clientInfo.showshelfready == "1" ? true : false;
      showShelfReadyDetails =
        clientInfo.showshelfreadydetails == "1" ? true : false;
    }

    let mrkr = 0;
    let mrkrsb = "";
    let mrkrsc = "";
    let mrkrsh = "";
    let mrkrsl = "";
    let mrkrsx = "";
    let mrkrsz = "";

    let spine = 0;
    let spinecolor = "";
    let spineline1 = "";
    let spineline2 = "";
    let spineline3 = "";
    let spineline4 = "";
    let spineline5 = "";
    let spineline6 = "";

    let shelfready = 0;
    let shelfreadydetails = "";

    if (showMarc && this.state.marcRecord) {
      mrkr = 1;
      mrkrsb = this._subfieldB._input.value || "";
      mrkrsc = this._subfieldC._input.value || "";
      mrkrsh = this._subfieldH._input.value || "";
      mrkrsl = this._subfieldL._input.value || "";
      mrkrsx = this._subfieldX._input.value || "";
      mrkrsz = this._subfieldZ._input.value || "";
    }
    if (showSpine && this.state.spineLabel) {
      spine = 1;
      spinecolor = this.state.color || "";
      spineline1 = this._line1._input.value || "";
      spineline2 = this._line2._input.value || "";
      spineline3 = this._line3._input.value || "";
      spineline4 = this._line4._input.value || "";
      spineline5 = this._line5._input.value || "";
      spineline6 = this._line6._input.value || "";
    }
    if (showShelfReady && this.state.shelfready) {
      shelfready = 1;

      if (showShelfReadyDetails) {
        shelfreadydetails = this._shelfReadyDetails._input.value || "";
      }
    }

    if (!this.state.requestRTA) {
      if (Cookies.get("referenceMandatory") == "1" && kref == "") {
        swal({
          title: "Please fill out mandatory fields",
          text: `Please fill out all fields marked with *`,
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
        return;
      }

      if (Cookies.get("budgetcodeMandatory") == "1" && code == "") {
        swal({
          title: "Please fill out mandatory fields",
          text: `Please fill out all fields marked with *`,
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
        return;
      }
    } else {
      if (
        rtaOrderData.fund == "" ||
        rtaOrderData.library == "" ||
        rtaOrderData.location == ""
      ) {
        swal({
          title: "Please fill out mandatory fields",
          text: `Please fill out all fields marked with *`,
          showConfirmButton: true,
          type: "error",
          allowOutsideClick: false,
        });
        return;
      }
    }

    this.setState({
      confirmModal: false,
      confirmModalAction: () => null,
      modalOpen: false,
    });

    const binding = volumeInfo.bindingText;
    const webCurrency = this.state.selectedOffer
      ? binding == "E-Book"
        ? this.state.selectedOffer.ClientCurrency
        : this.state.selectedOffer.currency
      : "";
    const webPrice = this.state.selectedOffer
      ? binding == "E-Book"
        ? this.state.selectedOffer.ClientList_Price
        : this.state.selectedOffer.price
      : "";

    this.props.addToCart(Cookies.get("subaccount"), {
      placeorder: 1,
      isbn: isbn,
      titl: title,
      auth: authors,
      kdat: moment().format("DD.MM.YYYY"),
      pdat: publishedYear,
      weig: weight,
      publ: publisher,
      elic: ebrary,
      kref,
      copy,
      urgt,
      acceptedduplicateorder: orderHistory ? "1" : "",
      detl: details,
      mrkr,
      mrkrsb,
      mrkrsc,
      mrkrsh,
      mrkrsl,
      mrkrsx,
      mrkrsz,
      spine,
      spinecolor,
      spineline1,
      spineline2,
      spineline3,
      spineline4,
      spineline5,
      spineline6,
      shelfready,
      shelfreadydetail: shelfreadydetails,
      hanparkey: this.state.selectedOffer
        ? this.state.selectedOffer.hanparkey
        : "",
      webPrice,
      webCurrency,
      offerTextDescription: this.state.selectedOffer
        ? this.state.selectedOffer.textDescription
        : "",
      ...(!this.state.requestRTA && notRtaOrderData),
      ...(this.state.requestRTA && rtaOrderData),
    });
  };

  _quickAddToCart = () => {
    const volumeInfo = this.props.data.volumeInfo || {};
    const title = volumeInfo.title || "";
    const authors = volumeInfo.authors || "";
    const isbn = `${volumeInfo.isbn13}` || "";
    const publisher = volumeInfo.publisher || "";
    const publishedYear = volumeInfo.publishedYear || "";
    const weight = volumeInfo.weight || "";

    const copy = "1";

    const binding = volumeInfo.bindingText;
    const webCurrency = this.state.selectedOffer
      ? binding == "E-Book"
        ? this.state.selectedOffer.ClientCurrency
        : this.state.selectedOffer.currency
      : "";
    const webPrice = this.state.selectedOffer
      ? binding == "E-Book"
        ? this.state.selectedOffer.ClientList_Price
        : this.state.selectedOffer.price
      : "";

    this.props.addToCart(Cookies.get("subaccount"), {
      placeorder: 1,
      isbn: isbn,
      titl: title,
      auth: authors,
      kdat: moment().format("DD.MM.YYYY"),
      pdat: publishedYear,
      weig: weight,
      publ: publisher,
      copy,
      mrkr: 0,
      spine: 0,
      shelfready: 0,
      shelfreadydetail: "",
      hanparkey: this.state.selectedOffer
        ? this.state.selectedOffer.hanparkey
        : "",
      webPrice,
      webCurrency,
      offerTextDescription: this.state.selectedOffer
        ? this.state.selectedOffer.textDescription
        : "",
    });

    this._closeModal();
  };

  _closeModal = () => {
    this.setState({
      modalOpen: false,
      confirmModal: false,
      confirmModalAction: () => null,
    });
  };

  _fetchSellingInfo = () => {
    this.setState({
      loadingSpinner: true,
    });

    const volumeInfo = this.props.data.volumeInfo;
    const buyingInfo = this.props.data.buyingInfo;
    let isbn = volumeInfo.isbn13;

    if (isbn) isbn = isbn.replace(/-/g, "");

    reqwest({
      url: searchAPINo2,
      data: {
        f: "price",
        account: Cookies.get("subaccount"),
        login: Cookies.get("username"),
        isbn,
        hanparkey: buyingInfo.hanparkey,
      },
      method: "get",
      crossOrigin: true,
      success: (data) => {
        data = JSON.parse(data);
        if (data && data.matches && data.matches.length > 0) {
          this.setState({
            buyingInfo: data.matches[0].buyingInfo,
            sellingInfo: data.matches[0].sellingInfo,
            volumeInfo: data.matches[0].volumeInfo,
            fields: data.matches[0].fields,
            sellingInfoDisabled: true,
            loadingSpinner: false,
            selectedOffer: data.matches[0].sellingInfo
              ? data.matches[0].sellingInfo.offers[0]
              : null,
          });
        } else {
          this.setState({
            sellingInfo: "error",
            sellingInfoDisabled: true,
            loadingSpinner: false,
          });
        }
      },
    });
  };

  render() {
    const crawledAddition = this.props.data.crawledAddition || false;
    const clientInfo = this.props.data.clientInfo;
    const orderHistory = clientInfo && clientInfo.orderHistory;
    const volumeInfo =
      this.state.volumeInfo || this.props.data.volumeInfo || {};
    const buyingInfo =
      this.state.buyingInfo || this.props.data.buyingInfo || {};
    const alternateEditions = this.props.data.alternateEditions || null;
    const sellingInfo = this.state.sellingInfo;
    const fields = this.state.fields || this.props.data.fields || [];

    const ebrary = volumeInfo.availableEbrary;

    const image = volumeInfo.coverImgSmall;
    const title = volumeInfo.title;
    const authors = volumeInfo.authors;
    const binding = volumeInfo.bindingText;
    const description = volumeInfo.description;
    const tableOfContents = volumeInfo.tableOfContents;

    let showMarc = false;
    let showSpine = false;
    let showShelfReady = false;
    let showShelfReadyDetails = false;
    if (clientInfo) {
      showMarc = clientInfo.showmarc == "1" ? true : false;
      showSpine =
        binding != "E-Book" && clientInfo.showspine == "1" ? true : false;
      showShelfReady = clientInfo.showshelfready == "1" ? true : false;
      showShelfReadyDetails =
        clientInfo.showshelfreadydetails == "1" ? true : false;
    }

    return (
      <div
        className={`search-result-item ${
          binding == "E-Book" ? "is-eBook" : ""
        }`}
      >
        {this.state.loading && <Loader />}
        <div className="search-result-image">
          <div>
            {image ? (
              <img className="item-image" src={image} alt="" />
            ) : (
              <div className="item-image" />
            )}
            {!alternateEditions && ebrary && (
              <img className="item-ebrary" src={"/images/ebrary.jpg"} />
            )}
          </div>
        </div>
        <div className="search-result-details">
          <div className="item-title">
            {entities.decode(title || "")}{" "}
            {crawledAddition && <div className="search-result-new">NEW</div>}
          </div>
          {authors ? (
            <div className="item-author">
              {typeof authors == "object" && authors.length > 0 ? (
                <span>by </span>
              ) : null}
              {typeof authors == "object" &&
                authors &&
                authors.map((item, index) => {
                  if (!item) {
                    return;
                  } else if (index == 0) {
                    return <span key={index}>{entities.decode(item)}</span>;
                  } else {
                    return <span key={index}>, {entities.decode(item)}</span>;
                  }
                })}
            </div>
          ) : null}
          <div className="item-details">
            {Object.keys(fields).map((key) => (
              <div key={key} className="item-detail">
                <b>{key}</b> <RenderHTML html={fields[key]} />
              </div>
            ))}
          </div>
          <div className="item-links">
            <Tabs selectedIndex={-1}>
              <TabList>
                <Tab />
                {description ? <Tab>Description</Tab> : null}
                {tableOfContents ? <Tab>Table of Contents</Tab> : null}
                {Object.keys(buyingInfo).length > 0 &&
                Cookies.get("mainaccounts").indexOf("ALL") != -1 ? (
                  <Tab>Buying Info</Tab>
                ) : null}
                {orderHistory ? <Tab>Order History</Tab> : null}
                {sellingInfo &&
                sellingInfo != "error" &&
                Cookies.get("mainaccounts").indexOf("ALL") != -1 ? (
                  <Tab>Selling Info</Tab>
                ) : null}
              </TabList>
              <TabPanel />
              {description ? (
                <TabPanel
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              ) : null}
              {tableOfContents ? (
                <TabPanel
                  dangerouslySetInnerHTML={{
                    __html: tableOfContents,
                  }}
                />
              ) : null}
              {Object.keys(buyingInfo).length > 0 &&
              Cookies.get("mainaccounts").indexOf("ALL") != -1 ? (
                <TabPanel>
                  {buyingInfo.currency && (
                    <div>Currency: {buyingInfo.currency}</div>
                  )}
                  {buyingInfo.discount !== null &&
                    buyingInfo.discount !== undefined && (
                      <div>Discount: {buyingInfo.discount}</div>
                    )}
                  {buyingInfo.hanparkey && (
                    <div>Hanparkey: {buyingInfo.hanparkey}</div>
                  )}
                  {buyingInfo.landerkey && (
                    <div>Landerkey: {buyingInfo.landerkey}</div>
                  )}
                  {buyingInfo.listPrice && (
                    <div>ListPrice: {buyingInfo.listPrice}</div>
                  )}
                  {buyingInfo.sellerName && (
                    <div>SellerName: {buyingInfo.sellerName}</div>
                  )}
                  {buyingInfo.buyingPrice && (
                    <div>BuyingPrice: {buyingInfo.buyingPrice}</div>
                  )}
                  {buyingInfo.sellerCountry && (
                    <div>SellerCountry: {buyingInfo.sellerCountry}</div>
                  )}
                  {buyingInfo.handlingCharge && (
                    <div>Handling Charge: {buyingInfo.handlingCharge}</div>
                  )}
                  {buyingInfo.finalBuyingPrice && (
                    <div>Final Buying Price: {buyingInfo.finalBuyingPrice}</div>
                  )}
                  {buyingInfo.bsdinsztp && (
                    <div>Bsdinsztp: {buyingInfo.bsdinsztp}</div>
                  )}
                  {buyingInfo.priceRange && (
                    <div>PriceRange: {buyingInfo.priceRange}</div>
                  )}
                </TabPanel>
              ) : null}
              {sellingInfo &&
              sellingInfo != "error" &&
              Cookies.get("mainaccounts").indexOf("ALL") != -1 ? (
                <TabPanel>
                  {sellingInfo.buyingDiscountType && (
                    <div>
                      BuyingDiscountType: {sellingInfo.buyingDiscountType}
                    </div>
                  )}
                  {sellingInfo.krbiosType && (
                    <div>krbiosType: {sellingInfo.krbiosType}</div>
                  )}
                  {sellingInfo.krbiosnumber && (
                    <div>krbiosnumber: {sellingInfo.krbiosnumber}</div>
                  )}
                  {sellingInfo.currencyDate && (
                    <div>currencyDate: {sellingInfo.currencyDate}</div>
                  )}
                  {sellingInfo.discount && (
                    <div>discount: {sellingInfo.discount}</div>
                  )}
                  {sellingInfo.hiddenUpmark && (
                    <div>hiddenUpmark: {sellingInfo.hiddenUpmark}</div>
                  )}
                  {sellingInfo.preisbindung && (
                    <div>preisbindung: {sellingInfo.preisbindung}</div>
                  )}
                  {sellingInfo.mindestmarge && (
                    <div>mindestmarge: {sellingInfo.mindestmarge}</div>
                  )}
                  {sellingInfo.lineardyn && (
                    <div>lineardyn: {sellingInfo.lineardyn}</div>
                  )}
                  {sellingInfo.bremsart && (
                    <div>bremsart: {sellingInfo.bremsart}</div>
                  )}
                  {sellingInfo.basePriceType && (
                    <div>basePriceType: {sellingInfo.basePriceType}</div>
                  )}
                  {sellingInfo.basePricePC && (
                    <div>basePricePC: {sellingInfo.basePricePC}</div>
                  )}
                  {sellingInfo.internetFixPrice && (
                    <div>internetFixPrice: {sellingInfo.internetFixPrice}</div>
                  )}
                  {sellingInfo.salePricePC && (
                    <div>salePricePC: {sellingInfo.salePricePC}</div>
                  )}
                  {sellingInfo.salePricePC_1 && (
                    <div>salePricePC_1: {sellingInfo.salePricePC_1}</div>
                  )}
                  {sellingInfo.currency && (
                    <div>currency: {sellingInfo.currency}</div>
                  )}
                  {sellingInfo.salePriceCC && (
                    <div>salePriceCC: {sellingInfo.salePriceCC}</div>
                  )}
                  {sellingInfo.salePriceNetCC && (
                    <div>salePriceNetCC: {sellingInfo.salePriceNetCC}</div>
                  )}
                  {sellingInfo.notbremseMindestmarge && (
                    <div>
                      notbremseMindestmarge: {sellingInfo.notbremseMindestmarge}
                    </div>
                  )}
                  {sellingInfo.notbremseMustpriceCC && (
                    <div>
                      notbremseMustpriceCC: {sellingInfo.notbremseMustpriceCC}
                    </div>
                  )}
                  {sellingInfo.salePriceNetCC_1 && (
                    <div>salePriceNetCC_1: {sellingInfo.salePriceNetCC_1}</div>
                  )}
                  {sellingInfo.salePriceNetCC && (
                    <div>salePriceNetCC: {sellingInfo.salePriceNetCC}</div>
                  )}
                  {sellingInfo.salePriceCC_2 && (
                    <div>salePriceCC_2: {sellingInfo.salePriceCC_2}</div>
                  )}
                  {sellingInfo.notbremseRecalcComment && (
                    <div>
                      notbremseRecalcComment:{" "}
                      {sellingInfo.notbremseRecalcComment}
                    </div>
                  )}
                  {sellingInfo.salePricePC_3 && (
                    <div>salePricePC_3: {sellingInfo.salePricePC_3}</div>
                  )}
                  {sellingInfo.salePricePC_3 && (
                    <div>salePriceCC_3: {sellingInfo.salePriceCC_3}</div>
                  )}
                  {sellingInfo.salePriceNetCC_2 && (
                    <div>salePriceNetCC_2: {sellingInfo.salePriceNetCC_2}</div>
                  )}
                  {sellingInfo.marginAmountCC && (
                    <div>marginAmountCC: {sellingInfo.marginAmountCC}</div>
                  )}
                  {sellingInfo.marginPercent && (
                    <div>marginPercent: {sellingInfo.marginPercent}</div>
                  )}
                  {sellingInfo.pricetype && (
                    <div>pricetype: {sellingInfo.pricetype}</div>
                  )}
                  {sellingInfo.currencyISO && (
                    <div>currencyISO: {sellingInfo.currencyISO}</div>
                  )}
                </TabPanel>
              ) : null}
              {orderHistory ? (
                <TabPanel>
                  {orderHistory.map((item, index) => {
                    return <div key={index}>{item.summary}</div>;
                  })}
                </TabPanel>
              ) : null}
            </Tabs>
          </div>
          {alternateEditions && (
            <div className="Alternations">
              {alternateEditions.map((edition, index) => {
                return (
                  <AlternateEdition
                    key={index}
                    data={{
                      clientInfo: this.props.data.clientInfo,
                      ...edition,
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
        {!alternateEditions && (
          <div className="search-result-infos">
            {this.state.loadingSpinner ? (
              <div className="spinner" />
            ) : (
              sellingInfo &&
              sellingInfo.offers && (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #cccccc",
                    }}
                  >
                    {this.state.selectedOffer &&
                      (binding == "E-Book"
                        ? `${this.state.selectedOffer.ClientCurrency} ${this.state.selectedOffer.ClientList_Price}`
                        : `${this.state.selectedOffer.currency} ${this.state.selectedOffer.price}`)}
                  </div>
                  {sellingInfo.offers.length >= 1 &&
                    sellingInfo.offers.map((item, index) => {
                      return (
                        <Radio
                          key={item.textDescription}
                          defaultChecked={index === 0}
                          label={item.description}
                          value={item}
                          discriminator={item.textDescription}
                          onclick={(value) => {
                            this.setState({
                              selectedOffer: value,
                            });
                          }}
                          name={`offers-${volumeInfo.verlagkey || "0"}-${
                            volumeInfo.isbn13
                          }`}
                        />
                      );
                    })}
                  {/* {sellingInfo.offers.length !== 1 && (
                    <Selectbox
                      alignLabel="left"
                      label=""
                      className="search-result-infos-select"
                      defaultValue={sellingInfo.offers[0]}
                      value={JSON.stringify(this.state.selectedOffer)}
                      options={sellingInfo.offers.map((item) => {
                        return {
                          value: JSON.stringify(item),
                          name: item.description,
                        };
                      })}
                      updateAction={(value) => {
                        this.setState({
                          selectedOffer: JSON.parse(value),
                        });
                      }}
                    />
                  )} */}
                </div>
              )
            )}
            {Cookies.get("mainaccounts").indexOf("ALL") != -1 && (
              <Button
                disabled={this.state.sellingInfoDisabled}
                onClick={this._fetchSellingInfo}
              >
                {!this.state.sellingInfo && this.state.sellingInfoDisabled
                  ? "Couldn't Fetch Selling Info"
                  : "Fetch Selling Info"}
              </Button>
            )}
            {!this.props.rtaApiKey &&
              Cookies.get("donotshowfastoption") != "1" && (
                <Button
                  disabled={
                    Cookies.get("orderpermit") == "0" ||
                    (this.state.selectedOffer &&
                      this.state.selectedOffer.price == 0 &&
                      binding == "E-Book")
                  }
                  onClick={() => this._toggleConfirm(this._quickAddToCart)}
                >
                  Add to Cart <br /> (fast option)
                </Button>
              )}
            <Button
              disabled={
                Cookies.get("orderpermit") == "0" ||
                (this.state.selectedOffer &&
                  this.state.selectedOffer.price == 0 &&
                  binding == "E-Book")
              }
              type="primary"
              onClick={() => this._toggleConfirm(this._addToCart)}
            >
              Add to Cart <br /> (incl. reference)
            </Button>
            <Modal
              open={this.state.modalOpen}
              class="Modal-wide"
              onClose={this._closeModal}
            >
              <Textfield
                alignLabel="left"
                label="Reference"
                ref={(node) => (this._kref = node)}
                placeholder={Cookies.get("referenceText")}
                required={Cookies.get("referenceMandatory") == "1"}
                defaultValue={`${
                  this._queryString.initialsPrefixRef == 1
                    ? Cookies.get("firstname").charAt(0) +
                      Cookies.get("lastname").charAt(0)
                    : ""
                }${
                  this._queryString.standardRef
                    ? this._queryString.standardRef
                    : ""
                }`}
              />
              {!this.state.requestRTA && (
                <Textfield
                  alignLabel="left"
                  label="Budget Code"
                  ref={(node) => (this._code = node)}
                  placeholder="Your Budget Code"
                  required={Cookies.get("budgetcodeMandatory") == "1"}
                  autocompleteBudgetCode={Cookies.get("subaccount")}
                  defaultValue={`${
                    this._queryString.standardCode
                      ? this._queryString.standardCode
                      : ""
                  }`}
                />
              )}
              <Textfield
                alignLabel="left"
                label="Copies"
                type="number"
                ref={(node) => (this._copy = node)}
                placeholder="Copies"
                defaultValue="1"
              />
              <Textfield
                alignLabel="left"
                label="Order Date"
                ref={(node) => (this._kdat = node)}
                disabled={true}
                value={moment().format("DD.MM.YYYY")}
                placeholder="Order Date"
              />
              <Checkbox
                ref={(node) => (this._urgt = node)}
                onClick={(value) => value}
                value="1"
                label="Urgent"
                name={`urgent_${volumeInfo.isbn13}`}
              />
              <Textfield
                type="textarea"
                ref={(node) => (this._details = node)}
                placeholder="Details - note to Delbanco"
              />

              {/** TODO(lambert): Add RTA to here! */}

              {this.state.requestRTA && (
                <div>
                  <hr />
                  <strong>RTA Specific Information</strong>
                  <Selectbox
                    alignLabel="left"
                    label="Fund Code *"
                    required={true}
                    updateAction={(value) => {
                      this.setState({
                        fundCode: value,
                      });
                    }}
                    options={this.state.rtaFunds.map((item) => {
                      return {
                        name: `${item.name} (${item.code})`,
                        value: `${item.code}`,
                      };
                    })}
                  />
                  <Selectbox
                    alignLabel="left"
                    label="Library *"
                    required={true}
                    updateAction={(value) => {
                      this.setState({
                        library: value,
                      });
                    }}
                    options={this.state.rtaLibraries.map((item) => {
                      return {
                        name: `${item.name}`,
                        value: `${item.code}`,
                      };
                    })}
                  />
                  {this.state.library && (
                    <Fetch
                      url={bakScarterAPI}
                      addSessionData={false}
                      data={{
                        rtaAPI: 1,
                        action: "locations",
                        library: this.state.library,
                        apikey: this.props.rtaApiKey,
                      }}
                    >
                      {({ data }) => {
                        if (data && data.total_record_count > 0) {
                          return (
                            <Selectbox
                              alignLabel="left"
                              label="Location *"
                              required={true}
                              updateAction={(value) => {
                                const locationDescription = data.location.find(
                                  (l) => l.code === value
                                ).name;
                                this.setState({
                                  location: value,
                                  locationDescription,
                                });
                              }}
                              options={data.location.map((item) => {
                                return {
                                  name: `${item.name}`,
                                  value: `${item.code}`,
                                };
                              })}
                            />
                          );
                        }
                        return null;
                      }}
                    </Fetch>
                  )}
                  {this.state.rtaReportingCodes &&
                    this.state.rtaReportingCodes.length > 0 && (
                      <Selectbox
                        alignLabel="left"
                        label="Primary Reporting code"
                        required={false}
                        updateAction={(value) => {
                          this.setState({
                            reportingCode: value,
                          });
                        }}
                        options={[
                          { name: "Please choose", value: "" },
                          ...this.state.rtaReportingCodes.map((item) => {
                            return {
                              name: item,
                              value: item,
                            };
                          }),
                        ]}
                      />
                    )}
                  {this.state.rtaReportingCodes2 &&
                    this.state.rtaReportingCodes2.length > 0 && (
                      <Selectbox
                        alignLabel="left"
                        label="Secondary Reporting code"
                        required={false}
                        updateAction={(value) => {
                          this.setState({
                            reportingCode2: value,
                          });
                        }}
                        options={[
                          { name: "Please choose", value: "" },
                          ...this.state.rtaReportingCodes2.map((item) => {
                            return {
                              name: item,
                              value: item,
                            };
                          }),
                        ]}
                      />
                    )}

                  {this.state.rtaInterestedUser &&
                    this.state.rtaInterestedUser.showInterestedUsers === 1 && (
                      <Textfield
                        alignLabel="left"
                        label="Interested User"
                        required={false}
                        onChange={(value) => {
                          this.setState({
                            interestedUser: value,
                          });
                        }}
                      />
                    )}

                  {this.state.rtaReceivingNote &&
                    this.state.rtaReceivingNote.showReceivingNote === 1 && (
                      <Textfield
                        alignLabel="left"
                        label="Receiving note"
                        type="textarea"
                        required={false}
                        style={{
                          marginLeft: "0",
                          minHeight: "90px",
                        }}
                        onChange={(value) => {
                          this.setState({
                            receivingNote: value,
                          });
                        }}
                      />
                    )}
                </div>
              )}
              {/** TODO(lambert): Add RTA to here! */}

              {showMarc && (
                <div>
                  <hr />
                  <Checkbox
                    name={`marcRecord_${volumeInfo.isbn13}`}
                    onclick={(value) =>
                      this.setState({
                        marcRecord: value,
                      })
                    }
                    value="true"
                    label="Marc-Record"
                  />
                  {this.state.marcRecord && (
                    <div>
                      <div style={{ margin: "10px" }}>
                        Marc-Record field 852:
                      </div>
                      <Textfield
                        ref={(node) => (this._subfieldB = node)}
                        placeholder="Subfield b"
                      />
                      <Textfield
                        ref={(node) => (this._subfieldC = node)}
                        placeholder="Subfield c"
                      />
                      <Textfield
                        ref={(node) => (this._subfieldH = node)}
                        placeholder="Subfield h"
                      />
                      <Textfield
                        ref={(node) => (this._subfieldL = node)}
                        placeholder="Subfield l"
                      />
                      <Textfield
                        ref={(node) => (this._subfieldX = node)}
                        placeholder="Subfield x"
                      />
                      <Textfield
                        ref={(node) => (this._subfieldZ = node)}
                        placeholder="Subfield z"
                      />
                    </div>
                  )}
                </div>
              )}
              {showSpine && (
                <div>
                  <hr />
                  <Checkbox
                    name={`spineLabel_${volumeInfo.isbn13}`}
                    onclick={(value) =>
                      this.setState({
                        spineLabel: value,
                      })
                    }
                    defaultChecked={showSpine}
                    label="Spine Label"
                  />
                  {this.state.spineLabel && (
                    <div>
                      <Selectbox
                        alignLabel="top"
                        label="Color:"
                        updateAction={(value) =>
                          this.setState({ color: value })
                        }
                        options={[
                          {
                            name: `White`,
                            value: `white`,
                          },
                          {
                            name: `Red`,
                            value: `red`,
                          },
                          {
                            name: `Blue`,
                            value: `blue`,
                          },
                          {
                            name: `Yellow`,
                            value: `yellow`,
                          },
                          {
                            name: `Green`,
                            value: `green`,
                          },
                        ]}
                      />
                      <Textfield
                        ref={(node) => (this._line1 = node)}
                        placeholder="Line 1"
                      />
                      <Textfield
                        ref={(node) => (this._line2 = node)}
                        placeholder="Line 2"
                      />
                      <Textfield
                        ref={(node) => (this._line3 = node)}
                        placeholder="Line 3"
                      />
                      <Textfield
                        ref={(node) => (this._line4 = node)}
                        placeholder="Line 4"
                      />
                      <Textfield
                        ref={(node) => (this._line5 = node)}
                        placeholder="Line 5"
                      />
                      <Textfield
                        ref={(node) => (this._line6 = node)}
                        placeholder="Line 6"
                      />
                    </div>
                  )}
                </div>
              )}
              {showShelfReady && (
                <div>
                  <hr />
                  <Checkbox
                    name={`shelfready_${volumeInfo.isbn13}`}
                    onclick={(value) =>
                      this.setState({
                        shelfready: value,
                      })
                    }
                    defaultChecked={showShelfReady}
                    label="Shelf-Ready"
                  />
                  {this.state.shelfready && showShelfReadyDetails && (
                    <div>
                      <Textfield
                        type="textarea"
                        ref={(node) => (this._shelfReadyDetails = node)}
                        placeholder="Details"
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="button-wrapper">
                <Button type="danger" onClick={this._closeModal}>
                  Cancel
                </Button>
                <Button type="primary" onClick={this._submitAddToCart}>
                  Add to Cart
                </Button>
              </div>
            </Modal>
            <Modal open={this.state.confirmModal} onClose={this._closeModal}>
              <p>
                You already ordered this book once. <br />
                Are you sure, you want to order it again?
              </p>
              <div className="button-wrapper">
                <Button type="danger" onClick={this._closeModal}>
                  No
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.state.confirmModalAction()}
                >
                  Yes, add to Cart
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

SearchResultItem.propTypes = {
  data: React.PropTypes.object,
  addToCart: React.PropTypes.func,
  rtaApiKey: React.PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    rtaApiKey: state.cart.clientInfo.rta
      ? state.cart.clientInfo.rta.apikey
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (login, password, subaccount, item) => {
      dispatch(addToCart(login, password, subaccount, item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultItem);

import React from "react";
import Textfield from "../components/inputs/Textfield";
import Button from "../components/inputs/Button";
import queryString from "query-string";
import reqwest from "reqwest";
import { loginAPI } from "../settings";

class Passwordreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  _resetPassword = () => {
    const urlParams = queryString.parse(window.location.search);
    let token = urlParams.token;
    const userlogin = this._username._input.value;
    const password = this._password._input.value;
    if (this.state.error) return;

    reqwest({
      url: loginAPI,
      data: {
        dataset: "password",
        action: "reset",
        userlogin: userlogin,
        newpassword: password,
        token,
      },
      method: "get",
      crossOrigin: true,
      success: (data) => {
        data = JSON.parse(data);
        const errormessage = data[0].errormessage;
        if (errormessage) {
          this.setState({
            error: errormessage,
          });
        } else {
          window.location.href = "/logout";
        }
      },
    });
  };

  _onChangePassword = (value) => {
    const passwordExp =
      /(?=[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).*$/;

    if (value.length < 10) {
      this.setState({
        error: "The Password needs to be at least 10 characters long",
      });
      return;
    } else if (!passwordExp.test(value)) {
      this.setState({
        error: `The Password needs to contain at least one uppercase letter, one lowercase letter, one number and one special character (allowed: ${"!@#$%^&*()_+-=[]{};':\"\\|,.<>/?"})`,
      });
      return;
    }

    this.setState({
      error: null,
    });
  };

  render() {
    const urlParams = queryString.parse(window.location.search);
    let username = urlParams.benutzer || "";
    return (
      <div className="main-content">
        <div className="Login">
          <h2 className="PageTitle">Reset Password</h2>
          {this.state.error && (
            <div
              style={{ textAlign: "center", color: "red" }}
              dangerouslySetInnerHTML={{ __html: this.state.error }}
            />
          )}
          <Textfield
            ref={(node) => (this._username = node)}
            defaultValue={username}
            placeholder="Username"
          />
          <Textfield
            type="password"
            ref={(node) => (this._password = node)}
            onChange={this._onChangePassword}
            placeholder="New Password"
          />
          <div className="password-rules">
            Your new password must follow these guidelines:
            <ul>
              <li>10 characters or longer</li>
              <li>At least one capital and lower case letter</li>
              <li>At least one digit</li>
              <li>
                At least one special Character (allowed:{" "}
                {"!@#$%^&*()_+-=[]{};':\"\\|,.<>/?"})
              </li>
            </ul>
          </div>
          <Button type="primary" onClick={this._resetPassword}>
            Reset Password
          </Button>
        </div>
      </div>
    );
  }
}

export default Passwordreset;

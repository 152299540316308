import React from "react";
import Cookies from "js-cookie";
import currencyFormatter from "currency-formatter";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import Fetch from "../../components/Fetch";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import DateRangePicker from "../../components/inputs/DateRangePicker";
import Selectbox from "../../components/inputs/Selectbox";
import moment from "moment";
import Loader from "../../components/Loader";

import { browserHistory } from "react-router";
import queryString from "query-string";
import { loginAPI } from "../../settings";

class Invoices extends React.Component {
  static propTypes = {
    params: React.PropTypes.object,
  };

  constructor(props) {
    super(props);

    this._isAdmin = Cookies.get("mainaccounts").indexOf("ALL") >= 0;

    const searchParams = queryString.parse(props.params.searchparams);

    this.state = {
      subaccounts: Cookies.get("subaccount") ? Cookies.get("subaccount") : "",
      allsubaccounts: this._isAdmin ? "ALL" : Cookies.get("allsubaccounts"),
      fromdate: moment().subtract(3, "months").format("DD.MM.YYYY"),
      todate: moment().format("DD.MM.YYYY"),
      ...searchParams,
    };
  }

  _handleDateOnChangeFrom = (orderdate) => {
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      fromdate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/invoices/${searchParams}`);
  };

  _handleDateOnChangeTo = (orderdate) => {
    const oldSearchParams = queryString.parse(this.props.params.searchparams);
    const query = {
      ...this.state,
      ...oldSearchParams,
      todate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/invoices/${searchParams}`);
  };

  _handleSubmit = (e) => {
    e.preventDefault();

    const oldSearchParams = queryString.parse(this.props.params.searchparams);

    let subaccounts = Cookies.get("subaccount");
    if (this._subaccount && this._subaccount._input) {
      subaccounts = this._subaccount._input.value;
    } else if (oldSearchParams && oldSearchParams.subaccount) {
      subaccounts = oldSearchParams.subaccount;
    }

    const invoiceid = this._invoiceid._input.value;
    const code = this._code._input.value;

    const query = {
      ...this.state,
      ...oldSearchParams,
      subaccounts,
      invoiceid,
      code,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/invoices/${searchParams}`);
    return false;
  };

  render() {
    const searchParams = queryString.parse(this.props.params.searchparams);
    const state = {
      ...this.state,
      ...searchParams,
    };
    return (
      <div>
        <form className="search-form" onSubmit={this._handleSubmit}>
          <div className="row">
            {Cookies.get("mainaccounts").indexOf("ALL") == -1 ? (
              <Fetch
                url={loginAPI}
                data={{
                  dataset: "profile",
                }}
              >
                {({ data }) =>
                  data ? (
                    <Selectbox
                      alignLabel="none"
                      label=""
                      value={state.subaccounts}
                      updateAction={(value) => {
                        const query = {
                          ...state,
                          subaccounts: value,
                        };
                        const searchParams = queryString.stringify(query);
                        browserHistory.push(`/invoices/${searchParams}`);
                      }}
                      options={[
                        {
                          name: "All Subaccounts",
                          value: "",
                        },
                        ...(data[0].subaccounts &&
                          data[0].subaccounts.map((item) => {
                            return {
                              name: item.account,
                              value: item.account,
                            };
                          })),
                      ]}
                    />
                  ) : null
                }
              </Fetch>
            ) : (
              <Textfield
                ref={(node) => (this._subaccount = node)}
                placeholder={`Subaccount (default: ${state.subaccounts})`}
              />
            )}
            <Textfield
              ref={(node) => (this._invoiceid = node)}
              placeholder="Invoice number"
            />
            <Textfield
              ref={(node) => (this._code = node)}
              autocompleteBudgetCode={state.subaccounts}
              placeholder="Budget code"
            />
          </div>
          <div className="row">
            <DateRangePicker
              defaultValue={state.fromdate}
              placeholder="Your order date (from)"
              onChange={this._handleDateOnChangeFrom}
            />
            <DateRangePicker
              defaultValue={state.todate}
              placeholder="Your order date (to)"
              onChange={this._handleDateOnChangeTo}
            />
            <Button type="primary" onClick={this._handleSubmit}>
              Search
            </Button>
          </div>
        </form>
        <div className="Invoices">
          <h2 className="PageTitle">Invoices</h2>
          {
            <Fetch
              url={loginAPI}
              data={{
                dataset: "invoice",
                allsubaccounts: state.allsubaccounts,
                subaccount: state.subaccounts,
                invoiceid: state.invoiceid || "",
                code: state.code || "",
                fromdate: state.fromdate,
                todate: state.todate,
              }}
            >
              {({ data, done }) =>
                done ? (
                  data && data.length > 0 && !data[0].errormessage ? (
                    <div>
                      <Alert align="right">
                        Total Amount:{" "}
                        {currencyFormatter.format(
                          data &&
                            data[0].totals[0].types
                              .filter((item) => item.I)[0]
                              .I[0].totalamount.replace(",", "."),
                          {
                            symbol: data[0].totals[0].types.filter(
                              (item) => item.I
                            )[0].I[0].currency,
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          }
                        )}
                      </Alert>
                      <Table
                        columns={[
                          "Invoice Number",
                          "SUB-ACCOUNT",
                          "Date",
                          "Amount",
                          "",
                        ]}
                      >
                        {data[0].item.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Column>{item.id}</Column>
                              <Column>{item.subaccount}</Column>
                              <Column>{item.date}</Column>
                              <Column>
                                {item.total
                                  ? currencyFormatter.format(item.total, {
                                      symbol: item.currency,
                                      decimal: ",",
                                      thousand: ".",
                                      precision: 2,
                                      format: "%v %s", // %s is the symbol and %v is the value
                                    })
                                  : "?"}
                              </Column>
                              <Column>
                                <Button
                                  type="default"
                                  href={`${loginAPI}?dataset=invoice&userlogin=${Cookies.get(
                                    "username"
                                  )}&allsubaccounts=${
                                    this.state.allsubaccounts
                                  }&subaccount=${
                                    item.subaccount
                                  }&sessionid=${Cookies.get(
                                    "sessionID"
                                  )}&fromdate=${this.state.fromdate}&todate=${
                                    this.state.todate
                                  }&invoiceid=${item.id}&type=${
                                    item.type
                                  }&pdf=1`}
                                >
                                  Details
                                </Button>
                              </Column>
                            </Row>
                          );
                        })}
                      </Table>
                    </div>
                  ) : (
                    <Alert type="danger">
                      No matches found... <br />
                      Please check your search parameters.
                    </Alert>
                  )
                ) : (
                  <Loader />
                )
              }
            </Fetch>
          }
        </div>
      </div>
    );
  }
}

export default Invoices;

import React from "react";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: this.props.initialHidden || false
    };
  }
  componentDidMount() {
    setTimeout(() => {
      if (window.innerWidth <= 1024) {
        this.setState({
          hidden: true
        });
      }
    }, 1000);
  }

  _toggleSidebar = () => {
    this.setState({
      hidden: !this.state.hidden
    });
  };

  render() {
    return (
      <div className="sidebar-content-wrapper">
        <div className={`sidebar-wrapper ${this.state.hidden ? "in" : "out"}`}>
          {this.props.sidebar}
          <div className="sidebar-handle" onClick={this._toggleSidebar} />
        </div>
        <div className="content-wrapper">{this.props.content}</div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebar: React.PropTypes.node,
  content: React.PropTypes.node,
  initialHidden: React.PropTypes.bool
};

Sidebar.defaultProps = {
  sidebar: null,
  content: null,
  initialHidden: false
};

export default Sidebar;

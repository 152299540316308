import React from "react";
import Cookies from "js-cookie";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import Fetch from "../../components/Fetch";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import moment from "moment";
import Loader from "../../components/Loader";

import { browserHistory } from "react-router";
import queryString from "query-string";
import { loginAPI } from "../../settings";

class ReadyToShip extends React.Component {
  static propTypes = {
    params: React.PropTypes.object,
  };

  constructor(props) {
    super(props);
    const searchParams = queryString.parse(props.params.searchparams);
    this._isAdmin = Cookies.get("mainaccounts").indexOf("ALL") >= 0;
    this.state = {
      ...searchParams,
    };
  }
  componentDidMount() {
    const interval = setInterval(() => {
      const rackboxID =
        this._rackbox && this._rackbox._input && this._rackbox._input.value;
      if (rackboxID) {
        const query = {
          ...this.state,
          rackboxID,
        };
        const searchParams = queryString.stringify(query);

        this.setState({
          rackboxID: rackboxID,
        });

        browserHistory.push(`/ready-to-ship/${searchParams}`);
      }
      if (rackboxID) {
        clearInterval(interval);
      }
    }, 100);
  }

  _handleSubmit = (event) => {
    event.preventDefault();
    const rackboxID = this._rackbox._input.value;

    const query = {
      ...this.state,
      rackboxID,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/ready-to-ship/${searchParams}`);

    // this.setState({
    //     rackboxID
    // });
    return false;
  };

  render() {
    const searchParams = queryString.parse(this.props.params.searchparams);
    const state = {
      ...this.state,
      ...searchParams,
    };

    return (
      <div>
        <form className="search-form" onSubmit={this._handleSubmit}>
          <div className="row">
            <Fetch
              url={loginAPI}
              data={{
                dataset: "profile",
              }}
            >
              {({ data, done }) =>
                done ? (
                  data ? (
                    (data[0].subaccounts &&
                      data[0].subaccounts
                        .map((item) => {
                          if (item.account == Cookies.get("subaccount")) {
                            const newRackbox =
                              this._isAdmin && state.rackboxID
                                ? state.rackboxID
                                : item.rackboxid;
                            state.rackboxID = newRackbox;
                            return this._isAdmin ? (
                              <Textfield
                                ref={(node) => (this._rackbox = node)}
                                defaultValue={state.rackboxID}
                                placeholder="Rackbox ID"
                              />
                            ) : (
                              <Textfield
                                ref={(node) => (this._rackbox = node)}
                                value={state.rackboxID}
                                placeholder="Rackbox ID"
                              />
                            );
                          }
                        })
                        .filter((item) => item)[0]) || (
                      <Textfield
                        ref={(node) => (this._rackbox = node)}
                        defaultValue={""}
                        disabled={!this._isAdmin}
                        placeholder="Rackbox ID"
                      />
                    )
                  ) : (
                    <Textfield
                      ref={(node) => (this._rackbox = node)}
                      defaultValue={""}
                      disabled={!this._isAdmin}
                      placeholder="Rackbox ID"
                    />
                  )
                ) : (
                  <Textfield
                    ref={(node) => (this._rackbox = node)}
                    defaultValue={""}
                    disabled={!this._isAdmin}
                    placeholder="Rackbox ID"
                  />
                )
              }
            </Fetch>
            <Button type="primary" onClick={this._handleSubmit}>
              Search
            </Button>
          </div>
        </form>
        <div className="ReadyToShip">
          <h2 className="PageTitle">Ready to Ship</h2>
          {state.rackboxID && (
            <Fetch
              url={loginAPI}
              data={{
                dataset: "rackbox",
                rackboxid: state.rackboxID,
              }}
            >
              {({ data, done }) =>
                done ? (
                  data ? (
                    <div>
                      <Table
                        columns={["Control Tag", "Title", "Date", "Time", ""]}
                      >
                        {data.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Column>{item.controltag}</Column>
                              <Column align="left">{item.titel}</Column>
                              <Column>
                                {moment(item.date).format("DD.MM.YYYY")}
                              </Column>
                              <Column>
                                {moment(item.date).format("HH:mm")}
                              </Column>
                              <Column>
                                <Button
                                  type="default"
                                  to={`/shipping/${item.orderno}`}
                                >
                                  Details
                                </Button>
                              </Column>
                            </Row>
                          );
                        })}
                      </Table>
                    </div>
                  ) : (
                    <div>
                      <Alert type="danger">Your rackbox is empty</Alert>
                    </div>
                  )
                ) : (
                  <div>
                    <Loader />
                  </div>
                )
              }
            </Fetch>
          )}
        </div>
      </div>
    );
  }
}

export default ReadyToShip;

import React from "react";
import Cookies from "js-cookie";
import Textfield from "../components/inputs/Textfield";
import Button from "../components/inputs/Button";
import Loader from "../components/Loader";
// import { browserHistory } from 'react-router';
// import queryString from 'query-string';
import Fetch from "../components/Fetch";
import { loginAPI } from "../settings";

class ChangeSubAccount extends React.Component {
  static propTypes = {
    onClose: React.PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      subaccount: Cookies.get("subaccount"),
    };
  }

  _setDefaultSubaccount = (account) => {
    this.setState({
      subaccount: account,
    });
  };

  _saveChanges = () => {
    if (this.state.subaccount != false) {
      Cookies.set("subaccount", this.state.subaccount);
    }
    window.location.reload();
  };

  render() {
    return (
      <Fetch
        url={loginAPI}
        data={{
          dataset: "profile",
        }}
      >
        {({ data }) =>
          data ? (
            <div className="Profile">
              <h2 className="PageTitle">Change Sub-Account</h2>
              <div className="Profile-data">
                <div className="Profile-subaccount">
                  <div className="Profile-subaccountLabel">
                    Currently active Sub-Account: {Cookies.get("subaccount")}
                    <hr />
                  </div>
                  <div className="Profile-subaccountLabel">
                    <b>Select Sub-Account:</b>
                  </div>
                  {data[0].subaccounts &&
                    data[0].subaccounts.map((item, index) => {
                      return (
                        <label
                          htmlFor={`subaccount_${item.account}`}
                          key={index}
                        >
                          <input
                            checked={
                              this.state.subaccount
                                ? this.state.subaccount == item.account
                                : data[0].defaultsubaccount == item.account
                            }
                            id={`subaccount_${item.account}`}
                            type="radio"
                            name="defaultsubaccount"
                            onChange={this._setDefaultSubaccount.bind(
                              this,
                              item.account
                            )}
                          />
                          <span>{item.account}</span>
                          <span>{item.name && item.name}</span>
                        </label>
                      );
                    })}
                </div>
                {data[0].mainaccounts &&
                  data[0].mainaccounts
                    .map((item) => {
                      return item.account;
                    })
                    .indexOf("ALL") != -1 && (
                    <Textfield
                      onChange={this._setDefaultSubaccount}
                      label="or enter Sub-Account manually"
                      value={this.state.subaccount}
                    />
                  )}
              </div>
              <br />
              <div className="button-wrapper">
                <Button type="danger" onClick={this.props.onClose}>
                  Cancel
                </Button>
                <Button type="primary" onClick={this._saveChanges}>
                  Save changes
                </Button>
              </div>
            </div>
          ) : (
            <Loader />
          )
        }
      </Fetch>
    );
  }
}

export default ChangeSubAccount;

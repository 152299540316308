import React from "react";
import Selectbox from "../../components/inputs/Selectbox";
import Cookies from "js-cookie";
import reqwest from "reqwest";
import equal from "deep-equal";
import Mark from "mark.js";

import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import { searchAPI } from "../../settings";
import moment from "moment";

import ResultPage from "./ResultPage";
import queryString from "query-string";
import { connect } from "react-redux";
import { StickyContainer, Sticky } from "react-sticky";

import {
  addBook,
  addCrawledBook,
  showCrawledBooks,
  clearBooks,
} from "../../actions/bookActions";
import Button from "../../components/inputs/Button";

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      direction: "desc",
      limit: 10,
      loadMore: false,
      loading: false,
      refetching: true,
      totalResults: "",
    };

    this.page = 1;
  }

  componentDidMount() {
    this._search();
    this._mark();
  }

  componentDidUpdate(prevProps) {
    if (!equal(prevProps.params.searchparams, this.props.params.searchparams)) {
      this._search();
    }
    this._mark();
  }

  componentWillUnmount() {
    this.props.clearBooks();
    this.page = 1;
    if (this.currentReqwest) {
      this.currentReqwest.abort();
    }
  }

  _mark = () => {
    const searchParams = queryString.parse(this.props.params.searchparams);
    if (this.markInstance) {
      this.markInstance.unmark();
    }
    this.markInstance = new Mark(this._searchResultElement);
    Object.keys(searchParams).forEach((key) => {
      if (key === "binding") return;
      if (key === "initialsPrefixRef") return;
      if (key === "standardCode") return;
      if (key === "standardRef") return;

      const punctuation = [
        '"',
        "!",
        "#",
        "$",
        "%",
        "&",
        "'",
        "(",
        ")",
        "*",
        "+",
        ",",
        "-",
        ".",
        "/",
        ":",
        ";",
        "<",
        "=",
        ">",
        "?",
        "@",
        "`",
        "_",
        "\\",
        "{",
        "|",
        "}",
        "~",
      ];

      let value = searchParams[key] || "";
      if (key === "title") {
        // Remove punctuation from title if it is at the end or start of a word
        const words = value.split(" ");
        words.forEach((word, index) => {
          if (punctuation.includes(word[0])) {
            words[index] = word.slice(1);
          }
          if (punctuation.includes(word[word.length - 1])) {
            words[index] = word.slice(0, word.length - 1);
          }
        });
        value = words.join(" ");
      }

      this.markInstance.mark(value, {
        exclude: [
          ".Alternation-label",
          ".button",
          ".item-detail > b",
          ".Alternation-title",
          ".Alternation-listPrice *",
          ".ReactTabs__Tab",
        ],
        accuracy: "exactly",
        wildcards: "enabled",
        ignorePunctuation: punctuation,
      });
    });
  };

  _search = () => {
    this.setState({
      refetching: true,
    });
    if (this.currentReqwest) {
      this.currentReqwest.abort();
    }
    this.props.clearBooks();
    this.page = 1;
    this._loadBooks(this.page, false);
    setTimeout(() => {
      this._loadBooks(1, true);
    }, 5000);
  };

  _setOrder = (order) => {
    this.setState({
      order,
    });
  };

  _setDirection = (direction) => {
    this.setState({
      direction,
    });
  };

  _showCrawledBooks = () => {
    const booksToShow = this.props.crawledBooks;
    if (this.currentReqwest) {
      this.currentReqwest.abort();
    }
    booksToShow.forEach((book) => {
      this.props.addBook({
        ...book,
        crawledAddition: true,
      });
    });
  };

  _loadBooks = (page, refetch) => {
    const searchParams = queryString.parse(this.props.params.searchparams);
    if (!refetch) {
      this.setState({
        loading: true,
      });
    }
    let excludeisbns = [];
    if (refetch) {
      excludeisbns = this.props.books.map((book) => book.volumeInfo.isbn13s);
    }

    this.currentReqwest = reqwest({
      url: searchAPI,
      data: {
        userlogin: Cookies.get("username"),
        sessionid: Cookies.get("sessionID"),
        subaccount: Cookies.get("subaccount"),
        punchoutid: Cookies.get("punchoutid") || null,
        f: "search",
        login: Cookies.get("username"),
        account: Cookies.get("subaccount"),
        ...searchParams,
        sortby: this.state.order,
        sortbymode: this.state.direction,
        maxresults: refetch ? this.state.limit * this.page : this.state.limit,
        timestamp: moment().valueOf(),
        page: page,
        refetch,
        excludeisbns: excludeisbns.join(","),
      },
      method: "get",
      crossOrigin: true,
      success: (data) => {
        this.currentReqwest = null;
        data = JSON.parse(data);
        if (!refetch) {
          this.setState({
            loading: false,
            totalResults:
              (data.meta && data.meta.total_found && data.meta.total_found) ||
              "",
          });
        } else if (refetch) {
          this.setState({
            refetching: false,
          });
        }
        if (data.matches && data.matches.length > 0) {
          data.matches.forEach((book) => {
            if (!refetch) {
              this.props.addBook(book);
            } else if (
              refetch &&
              !excludeisbns.includes(book.volumeInfo.isbn13s)
            ) {
              this.props.addCrawledBook(book);
            }
          });
        }
      },
    });
  };

  _renderRefetchBanner = () => {
    return !this.state.refetching &&
      this.props.crawledBooks &&
      this.props.crawledBooks.length !== 0 ? (
      <Alert type="info">
        We have found {this.props.crawledBooks.length} additional results!{" "}
        <Button onClick={this._showCrawledBooks}>Show me!</Button>
      </Alert>
    ) : (
      this.state.refetching && (
        <Alert type="info">
          <div className="spinner" />
          We are still continuing the search in the background...
        </Alert>
      )
    );
  };

  render() {
    if (
      !this.state.loading &&
      (!this.props.books || this.props.books.length === 0)
    ) {
      return (
        <div>
          {this._renderRefetchBanner()}
          <Alert type="danger">
            No matches found... <br />
            Please check your search parameters.
          </Alert>
        </div>
      );
    }

    return (
      <div className="search-results">
        {this.state.loading && <Loader />}
        <div className="search-results-order">
          <h4>Sort by</h4>
          <Selectbox
            alignLabel="left"
            label=""
            updateAction={this._setOrder}
            options={[
              { name: "None", value: "" },
              { name: "Published Year", value: "publishedYear" },
            ]}
          />
          <Selectbox
            alignLabel="left"
            label=""
            updateAction={this._setDirection}
            options={[
              { name: "Descending", value: "desc" },
              { name: "Ascending", value: "asc" },
            ]}
          />
        </div>
        <StickyContainer>
          {!this.state.loading && (
            <Sticky>
              {({ style }) => (
                <div
                  style={{
                    background: "#ffffff",
                    zIndex: 999,
                    margin: "0 -10px",
                    ...style,
                  }}
                >
                  {this._renderRefetchBanner()}
                </div>
              )}
            </Sticky>
          )}
          <div ref={(node) => (this._searchResultElement = node)}>
            <ResultPage
              totalResults={this.state.totalResults}
              onLoadMore={() => {
                this.page = this.page + 1;
                this._loadBooks(this.page);
              }}
            />
          </div>
        </StickyContainer>
      </div>
    );
  }
}

SearchResult.propTypes = {
  params: React.PropTypes.object,
  books: React.PropTypes.array,
  crawledBooks: React.PropTypes.array,
  addBook: React.PropTypes.func,
  addCrawledBook: React.PropTypes.func,
  clearBooks: React.PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    books: state.books,
    crawledBooks: state.crawledBooks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBook: (book) => {
      dispatch(addBook(book));
    },
    addCrawledBook: (book) => {
      dispatch(addCrawledBook(book));
    },
    showCrawledBooks: () => {
      dispatch(showCrawledBooks());
    },
    clearBooks: () => {
      dispatch(clearBooks());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);

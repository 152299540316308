import React from "react";
import equal from "deep-equal";

class SelectboxMultiple extends React.Component {
  static propTypes = {
    options: React.PropTypes.array.isRequired,
    placeholder: React.PropTypes.string,
    tabIndex: React.PropTypes.number,
    value: React.PropTypes.array,
    updateAction: React.PropTypes.func,
  };

  static defaultProps = {
    tabIndex: 1,
  };

  constructor(props) {
    super(props);

    this._toggleOpen = this._toggleOpen.bind(this);

    this.state = {
      open: false,
      value: this.props.value || [],
    };
  }

  componentDidMount() {
    const value = this.state.value;
    this.props.updateAction(value);

    if (this._selectboxWrapper) {
      document.addEventListener("click", this._onClickOutside);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(this.props, nextProps) || !equal(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.options, prevProps.options)) {
      const value = this.state.value;
      this.props.updateAction(value);
    }
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  componentWillUnmount() {
    if (this._selectboxWrapper) {
      document.removeEventListener("click", this._onClickOutside);
    }
  }

  _onClickOutside = (e) => {
    if (!this._selectboxWrapper.contains(e.target)) {
      this.setState({
        open: false,
      });
    }
  };

  _onChange = () => {
    this.setState({
      validate: true,
    });
    const value = this.state.value;
    this.props.updateAction(value);
  };

  _toggleOpen = () => {
    console.info("clicked");
    this.setState({
      open: !this.state.open,
    });
  };

  _toggleValue = (option) => {
    return (e) => {
      e.preventDefault();

      const isAlreadySelected = this.state.value.indexOf(option.value) > -1;

      if (isAlreadySelected) {
        this.setState({
          value: this.state.value.filter(
            (value) => {
              return value !== option.value;
            },
            () => {
              this.props.updateAction(this.state.value);
            }
          ),
        });
      } else {
        this.setState(
          {
            value: [...this.state.value, option.value],
          },
          () => {
            this.props.updateAction(this.state.value);
          }
        );
      }
    };
  };

  render() {
    const valueLength = this.state.value.length;
    const valueLabel = valueLength !== 0 ? `${valueLength} selected` : "";

    return (
      <div
        className="selectbox-wrapper"
        ref={(node) => (this._selectboxWrapper = node)}
      >
        <div className="selectbox-input-wrapper">
          <button
            className="selectbox selectbox--multiple"
            type="button"
            onClick={this._toggleOpen}
          >
            {valueLabel || this.props.placeholder}
          </button>
        </div>
        <div
          className={`selectbox-options ${this.state.open ? "is-Active" : ""}`}
        >
          {this.props.options.map((option) => {
            return (
              <button
                className="selectbox-option"
                type="button"
                key={option.value}
                onClick={this._toggleValue(option)}
              >
                {this.state.value.indexOf(option.value) > -1 ? "✓ " : "  "}
                {option.name}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SelectboxMultiple;

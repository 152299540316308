import React from "react";
import ReactDOM from "react-dom";

class Modal extends React.Component {
  static propTypes = {
    class: React.PropTypes.string,
    children: React.PropTypes.node,
    onClose: React.PropTypes.func,
    open: React.PropTypes.bool,
  };

  static defaultProps = {
    class: "",
  };

  componentDidMount() {
    this.portal = document.createElement("div");
    this.portal.className =
      "Modal-wrapper" + (this.props.open ? " active" : "");
    document.body.appendChild(this.portal);
    if (this.props.open) {
      this._renderModalContent(this.props);
    }
  }

  componentDidUpdate() {
    if (this.props.open) {
      this._renderModalContent();
    }
    this.portal.className =
      "Modal-wrapper" + (this.props.open ? " active" : "");
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.portal);
    document.body.removeChild(this.portal);
  }

  _renderModalContent = () => {
    ReactDOM.render(
      <div className={`Modal ${this.props.class}`}>
        {React.Children.map(this.props.children, (item) => {
          return (
            item &&
            React.cloneElement(item, {
              onClose: this.props.onClose,
            })
          );
        })}
      </div>,
      this.portal
    );
  };

  render() {
    return null;
  }
}

export default Modal;

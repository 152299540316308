import React from "react";
import Textfield from "../../components/inputs/Textfield";
import Button from "../../components/inputs/Button";
import Fetch from "../../components/Fetch";
import moment from "moment";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import Row from "../../components/table/Row";
import Column from "../../components/table/Column";
import DateRangePicker from "../../components/inputs/DateRangePicker";
import Cookies from "js-cookie";
import Loader from "../../components/Loader";

import { browserHistory } from "react-router";
import queryString from "query-string";
import { loginAPI } from "../../settings";

class Parcels extends React.Component {
  static propTypes = {
    params: React.PropTypes.object,
  };

  constructor(props) {
    super(props);
    this._isAdmin = Cookies.get("mainaccounts").indexOf("ALL") >= 0;

    const searchParams = queryString.parse(props.params.searchparams);

    this.state = {
      fromdate: moment().subtract(3, "months").format("DD.MM.YYYY"),
      todate: moment().format("DD.MM.YYYY"),
      ...searchParams,
    };
  }

  _handleDateOnChangeFrom = (orderdate) => {
    const query = {
      ...this.state,
      fromdate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    this.setState({
      fromdate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    });
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/parcels/${searchParams}`);
  };

  _handleDateOnChangeTo = (orderdate) => {
    const query = {
      ...this.state,
      todate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    };
    this.setState({
      todate: orderdate ? moment(orderdate).format("DD.MM.YYYY") : "",
    });
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/parcels/${searchParams}`);
  };

  _handleSubmit = (event) => {
    event.preventDefault();
    const urlSearchParams = queryString.parse(this.props.params.searchparams);
    const rackboxid = this._rackbox._input.value;
    const parcelid = this._parcel._input.value;

    const query = {
      ...this.state,
      ...urlSearchParams,
      rackboxid,
      parcelid,
    };
    const searchParams = queryString.stringify(query);
    browserHistory.push(`/parcels/${searchParams}`);
    return false;
  };

  render() {
    const searchParams = queryString.parse(this.props.params.searchparams);
    const state = {
      ...this.state,
      ...searchParams,
    };
    return (
      <div>
        <form className="search-form" onSubmit={this._handleSubmit}>
          <div className="row">
            <DateRangePicker
              defaultValue={state.fromdate}
              placeholder="Your order date (from)"
              onChange={this._handleDateOnChangeFrom}
            />
            <DateRangePicker
              defaultValue={state.todate}
              placeholder="Your order date (to)"
              onChange={this._handleDateOnChangeTo}
            />
            <Fetch
              url={loginAPI}
              data={{
                dataset: "profile",
              }}
            >
              {({ data, done }) =>
                done ? (
                  data ? (
                    (data[0].subaccounts &&
                      data[0].subaccounts
                        .map((item) => {
                          if (item.account == Cookies.get("subaccount")) {
                            const newRackbox =
                              this._isAdmin && state.rackboxid
                                ? state.rackboxid
                                : item.rackboxid;
                            state.rackboxid = newRackbox;
                            return Cookies.get("mainaccounts").indexOf("ALL") ==
                              -1 ? (
                              <Textfield
                                ref={(node) => (this._rackbox = node)}
                                value={state.rackboxid}
                                placeholder="Rackbox ID"
                              />
                            ) : (
                              <Textfield
                                ref={(node) => (this._rackbox = node)}
                                defaultValue={state.rackboxid}
                                placeholder="Rackbox ID"
                              />
                            );
                          }
                        })
                        .filter((item) => item)[0]) || (
                      <Textfield
                        ref={(node) => (this._rackbox = node)}
                        defaultValue={""}
                        disabled={
                          Cookies.get("mainaccounts").indexOf("ALL") == -1
                        }
                        placeholder="Rackbox ID"
                      />
                    )
                  ) : (
                    <Textfield
                      ref={(node) => (this._rackbox = node)}
                      defaultValue={""}
                      disabled={
                        Cookies.get("mainaccounts").indexOf("ALL") == -1
                      }
                      placeholder="Rackbox ID"
                    />
                  )
                ) : (
                  <Textfield
                    ref={(node) => (this._rackbox = node)}
                    defaultValue={""}
                    disabled={Cookies.get("mainaccounts").indexOf("ALL") == -1}
                    placeholder="Rackbox ID"
                  />
                )
              }
            </Fetch>
            <Textfield
              ref={(node) => (this._parcel = node)}
              placeholder="Parcel Number"
            />
          </div>
          <div className="button-wrapper">
            <Button type="primary" onClick={this._handleSubmit}>
              Search
            </Button>
          </div>
        </form>
        <div className="Parcels">
          <h2 className="PageTitle">Parcels</h2>
          {state.starttime != "" && (
            <Fetch
              url={loginAPI}
              data={{
                dataset: "parcels",
                parcelid: state.parcelid && state.parcelid,
                rackboxid: state.rackboxid && state.rackboxid,
                fromdate: state.fromdate,
                todate: state.todate,
              }}
            >
              {({ data, done }) =>
                done ? (
                  data ? (
                    <Table
                      columns={[
                        "Parcel Number",
                        "Sending Date",
                        "Shipping Partner",
                        "Shipping Method",
                        "UPS tracking",
                        "",
                      ]}
                    >
                      {data.map((item, index) => {
                        return (
                          <Row key={index}>
                            <Column>{item.parcelid}</Column>
                            <Column>{item.senddate}</Column>
                            <Column>{item.shippingprtner}</Column>
                            <Column>{item.shippingmethod}</Column>
                            <Column>
                              <a
                                target="_blank"
                                href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${item.trackingid}`}
                              >
                                {item.trackingid}
                              </a>
                            </Column>
                            <Column>
                              <Button
                                type="default"
                                to={`/parcelsDetail/${item.parcelid}`}
                              >
                                Details
                              </Button>
                            </Column>
                          </Row>
                        );
                      })}
                    </Table>
                  ) : (
                    <Alert type="danger">
                      No matches found... <br />
                      Please check your search parameters.
                    </Alert>
                  )
                ) : (
                  <Loader />
                )
              }
            </Fetch>
          )}
        </div>
      </div>
    );
  }
}

export default Parcels;

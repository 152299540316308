import React from "react";
import { Link } from "react-router";
import Modal from "../Modal";

class NavigationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      modalOpen: false
    };
  }

  _toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  _toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };

  render() {
    return this.props.children ? (
      <li
        className={`navigation-item hasChildren ${
          this.state.open ? "open" : ""
        }`}
      >
        <a
          onClick={this._toggleOpen}
          href="javascript:void(0)"
          dangerouslySetInnerHTML={{ __html: this.props.name }}
        />
        {this.state.open ? this.props.children : null}
      </li>
    ) : this.props.render ? (
      <li className={`navigation-item`}>
        <a
          onClick={this._toggleModal}
          href="javascript:void(0)"
          dangerouslySetInnerHTML={{ __html: this.props.name }}
        />
        <Modal open={this.state.modalOpen} onClose={this._toggleModal}>
          {this.props.render}
        </Modal>
      </li>
    ) : (
      <li className={`navigation-item`}>
        <Link
          activeClassName="is-active"
          to={`${this.props.path}`}
          dangerouslySetInnerHTML={{ __html: this.props.name }}
        />
      </li>
    );
  }
}

NavigationItem.propTypes = {
  render: React.PropTypes.node,
  children: React.PropTypes.node,
  name: React.PropTypes.string,
  path: React.PropTypes.string
};

NavigationItem.defaultProps = {
  children: null,
  render: null
};

export default NavigationItem;

import React from "react";

const TableColumn = props => {
  return (
    <div className={`Table-column ${props.align}`}>
      {props.children}
    </div>
  );
};

TableColumn.propTypes = {
  children: React.PropTypes.node,
  align: React.PropTypes.oneOf(["left", "center", "right"])
};

TableColumn.defaultProps = {
  children: null,
  align: "center"
};

export default TableColumn;

import React from "react";
import { Route, IndexRoute } from "react-router";

import App from "./routes/App";
import SecuredSection from "./routes/SecuredSection";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Passwordreset from "./routes/Passwordreset";
import RequestPasswordreset from "./routes/RequestPasswordreset";

import BookCatalogue from "./routes/bookCatalogue/BookCatalogue";
import CatalogueSearch from "./routes/bookCatalogue/SearchResult";

import Profile from "./routes/profile/Profile";
import ShippingDetail from "./routes/readyToShip/OrderDetail";
import ReadyToShip from "./routes/readyToShip/ReadyToShip";
import Parcels from "./routes/parcels/Parcels";
import ParcelDetail from "./routes/parcels/ParcelDetail";
import Invoices from "./routes/invoices/Invoices";
import NewTitleAlert from "./routes/NewTitleAlert";
import OrderTrackingBooks from "./routes/orderTrackingBooks/OrderTrackingBooks";
import OrderDetail from "./routes/orderTrackingBooks/OrderDetail";
import OrderDetailSeries from "./routes/orderTrackingSerials/OrderDetail";
import OrderTrackingSerials from "./routes/orderTrackingSerials/OrderTrackingSerials";
import SingleOrderDetailSerials from "./routes/orderTrackingSerials/SingleOrderDetail";

export default (
  <Route component={App}>
    <Route path="/" component={SecuredSection}>
      <IndexRoute component={BookCatalogue} />
      <Route path="catalogue" component={BookCatalogue}>
        <Route path="search/:searchparams" component={CatalogueSearch} />
      </Route>
      <Route path="profile" component={Profile} />
      <Route path="ready-to-ship(/:searchparams)" component={ReadyToShip} />
      <Route path="shipping/:orderID" component={ShippingDetail} />
      <Route path="invoices(/:searchparams)" component={Invoices} />
      <Route path="alerts" component={NewTitleAlert} />
      <Route
        path="tracking-books(/:searchparams)"
        component={OrderTrackingBooks}
      />
      <Route
        path="tracking-serials(/:searchparams)"
        component={OrderTrackingSerials}
      />
      <Route path="parcels(/:searchparams)" component={Parcels} />
      <Route path="parcelsDetail/:parcelID" component={ParcelDetail} />
      <Route path="orderDetail/:orderID" component={OrderDetail} />
      <Route
        path="orderDetail/:orderID/series/:seriesID"
        component={OrderDetailSeries}
      />
      <Route
        path="orderDetailSeries/:orderID/:band"
        component={SingleOrderDetailSerials}
      />
    </Route>
    <Route path="/requestpasswordreset" component={RequestPasswordreset} />
    <Route path="/passwordreset" component={Passwordreset} />
    <Route path="/logout" component={Logout} />
    <Route path="/login" component={Login} />
  </Route>
);

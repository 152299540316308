import React from "react";
import { Link } from "react-router";

const Logo = () => (
  <Link to="/">
    <img className="logo" src="/images/logo.png" alt="Delbanco" />
  </Link>
);

export default Logo;

import React from "react";
import { Link } from "react-router";

const Button = props => {
  return props.href ? (
    <a
      target={props.target}
      href={props.disabled ? "javascript:void(0);" : props.href}
      className={`button button-${props.type}  ${
        props.disabled ? "button-disabled" : ""
      }`}
    >
      {props.children}
    </a>
  ) : props.to ? (
    <Link
      to={props.disabled ? null : props.to}
      className={`button button-${props.type} ${
        props.disabled ? "button-disabled" : ""
      }`}
    >
      {props.children}
    </Link>
  ) : (
    <button
      type={props.type == "primary" ? "submit" : "button"}
      role="button"
      onClick={props.disabled ? null : props.onClick}
      className={`button button-${props.type} ${
        props.disabled ? "button-disabled" : ""
      }`}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  children: React.PropTypes.node,
  onClick: React.PropTypes.func,
  href: React.PropTypes.string,
  to: React.PropTypes.string,
  target: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  type: React.PropTypes.oneOf(["default", "primary", "danger", "default-green"])
};

Button.defaultProps = {
  disabled: false,
  children: null,
  type: "default",
  target: "_blank"
};

export default Button;
